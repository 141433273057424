import React, { useEffect, useState } from "react";
import axios from "axios";
import io from 'socket.io-client'
import "../static/Indicadores.css";
import { Link, useHistory} from "react-router-dom";
import swal from "sweetalert";
import ProgressBar from "@ramonak/react-progress-bar";

// const socket = io('http://localhost:4000')

const RotacionSeller = ({
  SetLandingHeader,
  apiUrlState,
  recursosUrlState,
  modificarSpinnerState,
}) => {
  const VolverAdmin = () => {
    window.location.href = "/Admin/Index/MenuUnidades";
  };

  const [Periodo, GetPeriodo] = useState(["PRUEBA 2021"]);
  const [Periodo2, SetPeriodo2] = useState([{anio: "", mes: "", Periodo: "AGOSTO 2023"}]);
  const [Fechas, SetFechas] = useState({ FechaIni: "", FechaFin: "" });
  const [Progreso, SetProgreso] = useState(0);
  // const [Mensaje, SetMensaje] = useState("");

  const Periodos = async () => {
    await axios
      .post(apiUrlState + "/Api/Unidades/GetPeriodos", {})
      .then((response) => {
        GetPeriodo(response.data.data);
      })
      .catch(function (err) {
        console.log("Error");
      });
  };

  const VerificarPeriodo = ()=> {
    let existe = false;
    for(const periodo of Periodo) {
      if(periodo == Periodo2.Periodo){
        existe = true;
      }
    }
    return existe
  }

  const actualizarProgreso = () => {
    const socket = io(apiUrlState);
    socket.on('Progreso', (pr) => {
      console.log(pr);
      SetProgreso(pr.Progreso);
      // SetMensaje(pr.Mensaje);
    });
  }

  const cargarRotacion = async () => {
    if(Periodo2.Periodo != undefined){
      let res = VerificarPeriodo(Periodo2.Periodo)
      if(res) {
        swal({
          title: "El informe ya ha sido generado anteriormente",
          text: "Si continúa el informe será reemplazado con los datos actuales",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async(continuar) => {
          if (continuar) {
            modificarSpinnerState({
              Desplegar: true,
              Texto: "actualizando rotacion",
            });
            await axios
              .post(apiUrlState + "/Api/Unidades/CargarRotacion", Periodo2)
              .then((response) => {
                Periodos();
                modificarSpinnerState({
                  Desplegar: false,
                  Texto: "actualizando rotacion",
                });
                console.log("Carga completa");
              })
              .catch(function (err) {
                console.log("Error");
              });
          }
        });
      }else {
        modificarSpinnerState({
          Desplegar: true,
          Texto: "actualizando rotacion",
        });
        await axios
          .post(apiUrlState + "/Api/Unidades/CargarRotacion", Periodo2)
          .then((response) => {
            Periodos();
            modificarSpinnerState({
              Desplegar: false,
              Texto: "actualizando rotacion",
            });
            console.log("Carga completa");
          })
          .catch(function (err) {
            console.log("Error");
          });
      }
    }
  }

  const onChangePeriodo2 = (value) => {
    let periodo = value.split("-");
    let anio = periodo[0];
    let mes = parseInt(periodo[1]) -1;
    const meses = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
    let stringMes = meses[mes];
    let dataPeriodo = stringMes + " " + anio;
    SetPeriodo2({ anio: anio, mes: mes+1, Periodo: dataPeriodo });
  }


  const onChangeFecha = (fecha, tipo) => {
    if(tipo === "FechaI") {
      SetFechas({ ...Fechas, FechaIni: fecha });
    } else if(tipo === "FechaF") {
      let fechaI = new Date(Fechas.FechaIni);
      let fechaF = new Date(fecha);
      if(fechaF < fechaI) {
        console.log("la fecha final no puede ser anterior a la fecha inicial")
      } else {
        SetFechas({ ...Fechas, FechaFin: fecha });
      }
    }
  }

  const onChangePeriodo_2 = (periodo) => {
    const fechaActual = new Date();
    const anio = fechaActual.getFullYear();
    const mes = fechaActual.getMonth() + 1;
    const Periodo = periodo.toUpperCase();
    SetPeriodo2({ anio: anio, mes: mes, Periodo: Periodo });
  }

  const generarInformeRotacion = async() => {
    actualizarProgreso();
    if(Fechas.FechaIni != undefined && Fechas.FechaFin != undefined) {
      let res = VerificarPeriodo(Periodo2.Periodo);
      if(res) {
        swal({
          title: "El informe ya ha sido generado anteriormente",
          text: "Si continúa el informe será reemplazado con los datos actuales",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async(continuar) => {
          if (continuar) {
            modificarSpinnerState({
              Desplegar: true,
              Texto: "actualizando rotacion",
            });
            await axios
              .post(apiUrlState + "/Api/Unidades/GenerarInformeRotacion", {Periodo2, Fechas})
              .then((response) => {
                Periodos();
                modificarSpinnerState({
                  Desplegar: false,
                  Texto: "actualizando rotacion",
                });
                console.log("Carga completa");
              })
              .catch(function (err) {
                console.log("Error");
              });
          }
        });
      }else {
        modificarSpinnerState({
          Desplegar: true,
          Texto: "actualizando rotacion",
        });
        await axios
          .post(apiUrlState + "/Api/Unidades/GenerarInformeRotacion", {Periodo2, Fechas})
          .then((response) => {
            Periodos();
            modificarSpinnerState({
              Desplegar: false,
              Texto: "actualizando rotacion",
            });
            console.log("Carga completa");
          })
          .catch(function (err) {
            console.log("Error");
          });
      }          
    }
  }

  useEffect(()=> {
    if(Progreso === 100) {
      modificarSpinnerState({
        Desplegar: false,
        Texto: "actualizando rotacion",
      });
      SetProgreso(0);
    } 
    // else if(Progreso !== 0) {
    //   modificarSpinnerState({
    //     Desplegar: true,
    //     Texto: Mensaje,
    //   })
    // }
  }, [Progreso])

  //Login validation
  const token = localStorage.getItem("token");
  const history = useHistory();
  useEffect(() => {
    if (token) {
      
    }else{
      history.push("/login")
    }
    //console.log(reports)
  },[token]);

  useEffect(() => {
    SetLandingHeader({ Estado: true });
    Periodos();
  }, []);

  return (
    <div>
      <h1
        style={{
          marginTop: "150px",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        ROTACIÓN DE UNIDADES
      </h1>

      <div className="container">
        {Progreso != 0? (
        <ProgressBar completed={Progreso} bgColor="black" />
        ) : (null)}
        <form style={{ marginTop: "50px" }}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="fecha_inicial" style={{ fontSize: "17px" }}>
                (*) Fecha Inicial
              </label>
              <input
                onChange={(e) => onChangeFecha(e.target.value, "FechaI")}
                type="date"
                style={{ fontSize: "15px" }}
                name="FechaNacimiento"
                id="fecha_inicial"
                min="1910-01-01"
                required
              ></input>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="fecha_final" style={{ fontSize: "17px" }}>
                (*) Fecha Final
              </label>
              <input
                onChange={(e) => onChangeFecha(e.target.value, "FechaF")}
                type="date"
                style={{ fontSize: "15px" }}
                name="FechaNacimiento"
                id="fecha_final"
                min="1910-01-01"
                required
              ></input>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="periodo" style={{ fontSize: "17px" }}>
                (*) Periodo
              </label>
              <input
                onChange={(e) => onChangePeriodo_2(e.target.value)}
                type="text"
                style={{ fontSize: "15px" }}
                placeholder="Ej: ENERO 2023"
                name="_periodo"
                id="periodo"
                min="1910-01-01"
                required
              ></input>
            </div> 
          </div>          

          <div style={{textAlign:"center",  paddingBottom: "25px"}}>
            <button
              type="button"
              className="btn btn- FuenteBebas"
              style={{ fontSize: "1.2rem", width: "20vw", border: "solid"}}
              onClick={ () => {generarInformeRotacion()}}
            >
              Generar Informe
            </button>
          </div>

        </form>
      </div>
      

      <div className="container">
        <div className="table-responsive">
          <table className="table">
            <thead style={{ marginTop: "50px", textAlign: "center" }}>
              <tr>
                <th
                  scope="col"
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                >
                  Mes
                </th>
                <th
                  scope="col"
                  colSpan="2"
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                >
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody>
              {Periodo.map((mes) =>
                mes !== "PRUEBA 2021" ? (
                  <tr style={{ textAlign: "center" }}>
                    <th style={{ textAlign: "center" }}>{mes}</th>
                    <th>
                      <Link to={"/Admin/Rotacion/Seller/" + mes}>
                        <button
                          type="button"
                          className="btn btn-dark"
                          style={{ fontSize: "10px", width: "120px" }}
                        >
                          Ver Mas
                        </button>
                      </Link>
                    </th>
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RotacionSeller;
