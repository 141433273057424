//Zona de importacion de componentes externos.
import React, { useEffect, useState } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
//Zona de importacion de Css.
import "./static/css/Filtros/Filtros.css";
import { useInsertionEffect } from "react";
import { Redirect } from 'react-router-dom';
import { usePreventaContext } from "../../providers/PreventaProviders";

const Filtros = ({
  filtrosState,
  modificarFiltrosState,
  productosState,
  modificarProductosState,
  tallasState,
  modificarTallasState,
  coloresState,
  modificarColoresState,
  marcasState,
  modificarMarcasState,
  medidasState,
  modificarMedidasState,
  errorState,
  modificarErrorState,
  spinnerState,
  modificarSpinnerState,
  apiUrlState,
  modificarApiUrlState,
  recursosUrlState,
  modificarRecursosUrlState,
  categoriasState,
  modificarCategoriasState,
  categoriasMujerState,
  modificarCategoriasMujerState,
  headerState,
  modificarHeaderState,
  CatalogoState,
  actualizarSessionStorage,
  subCategoriasState,
  modificarSubCategoriasState,
  refNoCatalogoState,
  modificarRefNoCatalogoState,
  checkedProductos,
  setCheckedProductos,
}) => {
  const { Bodega, BodegaCatalogos, setBodegaCatalogos } = usePreventaContext();


  //State de filtros en donde se extraen el Genero, Categoria, si hay cambio de filtro, si hay cambio de categoria.
  const { CambioCategoria, CambioFiltro, Categoria, Genero } = filtrosState;

  //Lo siguientes states son los de cada tipo de filtro aplicable, donde sus propiedades son la lista actual de filtros aplicados
  //para cada uno en la posicion de la lista y en la posicion 0 un json con true o false en cada atributo de este.

  //Propiedad para cuando un filtro es seleccionado y asi colocarle una linea mediante animacion.
  const seleccionado = {
    backgroundSize: "33% 4px",
    outline: "none",
    textDecoration: "none",
    padding: 0,
    border: 0,
  };

  const [listaCategoriasState, modificarListaCategoriasState] = useState({
    Hombre: [],
    Mujer: [],
    Niño: [],
  });
  const [listaMedidasState, modificarListaMedidasState] = useState({
    Hombre: [],
    Mujer: [],
  });
  const [listaMarcasState, modificarListaMarcasState] = useState([]);
  const [listaTallasState, modificarListaTallasState] = useState([]);
  const [listaColoresState, modificarListaColoresState] = useState([]);
  const [listaGenerosState, modificarListaGenerosState] = useState([]);
  const [TypeCat, modificarTypeCat] = useState({ tipo: "SinPrecio" });

  // Mostrar / Ocultar, cantidades por talla
  const [unidades, setUnidades] = useState(false);
  // Unidades minimas para ser agregadas al catalogo
  const [minUnidades, setMinUnidades] = useState(1);

  const [Ruta, setRuta] = useState(window.location.pathname);

  //Lleva a la vista de Inicio de Sesion.
  const llevarAMinorista = () => {
    window.location.href = "/";
  };

  const obtenerCategoriasApi = () => {
    axios
      .post(apiUrlState + "/Api/Categoria/ObtenerCategorias", {
        data: [
          {
            Estado: true,
            DeHombre: true,
          },
          {
            Estado: true,
            DeMujer: true,
          },
          {
            Estado: true,
            DeNino: true,
          },
        ],
      })
      .then((response) => {
        if (!response.data[0].error && !response.data[1].error) {
          modificarListaCategoriasState({
            Hombre: response.data[0].data,
            Mujer: response.data[1].data,
            Niño: response.data[2].data,
          });
          modificarListaTallasState(
            response.data[0].data[0].GrupoTallas.Tallas
          );
          if (errorState["Error"]) {
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          }
        }
      })
      .catch(function (err) {
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        }
      });
  };

  const obtenerMarcasApi = () => {
    axios
      .post(apiUrlState + "/Api/Marca/ObtenerMarcas", {
        data: {},
      })
      .then((response) => {
        if (!response.data.error) {
          let marcas = response.data.data;
          let marcasObj = [];
          marcas.map((marca) => {
            if (marca.Marca !== "QUEST" && marca.Marca !== "FISHBONE") {
              marcasObj.push(marca);
            }
          });
          modificarListaMarcasState(marcasObj);
          if (errorState["Error"]) {
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          }
        }
      })
      .catch(function (err) {
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        }
      });
  };

  const obtenerColoresApi = () => {
    axios
      .post(apiUrlState + "/Api/Color/ObtenerColores", {
        data: {},
      })
      .then((response) => {
        if (!response.data.error) {
          modificarListaColoresState(response.data.data);
          if (errorState["Error"]) {
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          }
        }
      })
      .catch(function (err) {
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        }
      });
  };

  const obtenerGenerosApi = () => {
    axios
      .post(apiUrlState + "/Api/Genero/ObtenerGeneros", {
        data: {},
      })
      .then((response) => {
        if (!response.data.error) {
          modificarListaGenerosState(response.data.data);
          if (errorState["Error"]) {
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          }
        }
      })
      .catch(function (err) {
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        }
      });
  };

  const obtenerMedidas = () => {
    // Esto puede hacerse desde la BD, pero no es necesario por el momento
    axios
      .get(apiUrlState + "/Api/Producto/GetMedidas", {
        data: {},
      })
      .then((response) => {
        if (!response.data.error) {
          let medidasH = response.data.data[0];
          let medidasM = response.data.data[1];
          let medidasObjH = [];
          let medidasObjM = [];
          medidasH.map((medida) => {
            if (medida !== "")
              medidasObjH.push({ _id: medida, Medida: medida });
          });
          medidasM.map((medida) => {
            if (medida !== "")
              medidasObjM.push({ _id: medida, Medida: medida });
          });
          modificarListaMedidasState({
            Hombre: medidasObjH,
            Mujer: medidasObjM,
          });
          if (errorState["Error"]) {
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          }
        }
      })
      .catch(function (err) {
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        }
      });
  };

  const comprobarCategoriaSeleccionada = (idCategoria, genero) => {
    return categoriasState[genero] === idCategoria;
  };

  const comprobarMarcaSeleccionada = (idMarca) => {
    for (let i = 0; i < marcasState.length; i++) {
      if (marcasState[i] === idMarca) return true;
    }
    return false;
  };

  const comprobarMedidaSeleccionada = (idMedida) => {
    for (let i = 0; i < medidasState.length; i++) {
      if (medidasState[i] === idMedida) {
        return true;
      }
    }
    return false;
  };

  const comprobarTallaSeleccionada = (idTalla) => {
    for (let i = 0; i < tallasState.length; i++) {
      if (tallasState[i] === idTalla) return true;
    }
    return false;
  };

  const comprobarColorSeleccionado = (idColor) => {
    for (let i = 0; i < coloresState.length; i++) {
      if (coloresState[i] === idColor) return true;
    }
    return false;
  };

  const capitalizarPrimeraLetra = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  //Funcion principal:
  // Despues obtener toda la lista de productos se verifica si hay seleccionados filtros actuales y se aplican a esta lista.
  //En caso de solicitar la seccion new se hace una peticion de ruta diferente.
  //Para la aplicacion de los filtros se trataron varios casos segun la cantidad de estos seleccionada.
  //(Este proceso se realizara mas adelante en el back, mediante mongo)
  const cambioMatriz = async () => {
    await modificarFiltrosState({
      Genero: Genero,
      Categoria: Categoria,
      CambioFiltro: false,
      CambioCategoria: false,
      filtroResumido: false,
      posicionFiltros: filtrosState["posicionFiltros"],
      width: filtrosState["width"],
    });
    let url = apiUrlState + "/Api/Inventario/GetProductos";
    let data = {
      Categorias: [],
      Generos: [],
      Marcas: [],
      Medidas: [],
      Tallas: [],
      Colores: [],
      Subcategoria: [],
      ParametroOrdenamiento: "",
      ProductosNuevos: false,
      Descuentos: false,
      Todo: false,
      Origen: "CambioMatriz"
    };
    let i;
    let categoriaALlamar;

    if (categoriasState.Hombre !== null) {
      categoriaALlamar = categoriasState.Hombre;
    } else if (categoriasState.Mujer !== null) {
      categoriaALlamar = categoriasState.Mujer;
    } else if (categoriasState.Niño !== null) {
      categoriaALlamar = categoriasState.Niño;
    } else if (filtrosState.Categoria === "New") {
      categoriaALlamar = null;
    } else {
      categoriaALlamar = "Descuento";
    }

    if (categoriaALlamar === "Descuento") {
      data["Descuentos"] = false;
    } else if (categoriaALlamar !== null) {
      // Para mostrar los bodys en la categoria blusas
      if(categoriaALlamar === "651aec4251072238a0969749") {
        data["Categorias"].push(
          { Categoria: categoriaALlamar }, 
          { Categoria: "655bad5a1cdc932b9e974a01" }
        );
      } else {
        data["Categorias"].push({ Categoria: categoriaALlamar });
      }
      if (categoriaALlamar !== "607ed63e521b1c0910a581ac")
        data["Generos"].push({ Genero: Genero });
    } else data["ProductosNuevos"] = true;

    for (i = 0; i < marcasState.length; i++) {
      data["Marcas"].push({ Marca: marcasState[i] });
    }
    for (i = 0; i < medidasState.length; i++) {
      data["Medidas"].push({ Medidas: medidasState[i] });
    }
    for (i = 0; i < tallasState.length; i++) {
      data["Tallas"].push({ "Variantes.Tallas.Talla": tallasState[i] });
    }
    for (i = 0; i < coloresState.length; i++) {
      data["Colores"].push({ "Variantes.Color": coloresState[i] });
    }
    if (subCategoriasState != "") {
      data["Subcategoria"].push({ Subcategoria: subCategoriasState });
    }

    if(window.location.pathname == "/Admin/GeneradorDeCatalogos") {
      data.Bodega = BodegaCatalogos;
    } else {
      data.Bodega = Bodega;
    }

    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Cargando productos...",
      });
    }

    modificarProductosState([
      { CambioSeccion: false, Busqueda: false, irInicio: true },
      [],
    ]);
    await axios
      .post(
        url,
        {
          data,
        },
        {}
      )
      .then((response) => {
        modificarProductosState([
          { CambioSeccion: false, Busqueda: false, irInicio: true },
          response.data.data,
        ]);
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        if (errorState["Error"]) {
          modificarErrorState({
            Error: false,
            Mensaje: "Todo bien, todo correcto",
          });
        }
      })
      .catch(function (err) {
        if (!errorState["Error"])
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
      });
  };

  //Limpia los states de todos los filtros para no dejar ninguno seleccionado.
  const limpiarFiltros = () => {
    if (
      categoriasState.Hombre !== null ||
      categoriasState.Mujer !== null ||
      categoriasState.Niño !== null
    )
      modificarCategoriasState({ Hombre: null, Mujer: null, Niño: null });
    if (tallasState.length > 0) modificarTallasState([]);
    if (coloresState.length > 0) modificarColoresState([]);
    if (marcasState.length > 0) modificarMarcasState([]);
    if (medidasState.length > 0) modificarMedidasState([]);
    modificarFiltrosState({
      Genero: Genero,
      Categoria: "New",
      CambioFiltro: true,
      CambioCategoria: false,
      filtroResumido: false,
      posicionFiltros: "-100%",
      width: "0px",
    });
  };
  const limpiarFiltros2 = async () => {
    modificarProductosState([
      { CambioSeccion: false, Busqueda: false, irInicio: true },
      [],
    ]);
    await modificarFiltrosState({
      Genero: Genero,
      Categoria: "Descuento",
      CambioFiltro: true,
      CambioCategoria: false,
      filtroResumido: false,
      posicionFiltros: "-100%",
      width: "0px",
    });
    let url = apiUrlState + "/Api/Inventario/GetProductos";
    let data = {
      Categorias: [],
      Generos: [],
      Marcas: [],
      Medidas: [],
      Tallas: [],
      Colores: [],
      Subcategoria: [],
      ParametroOrdenamiento: "",
      ProductosNuevos: false,
      Descuentos: true,
      Todo: false,
      Origen: "Limpiar"
    };

    if(window.location.pathname == "/Admin/GeneradorDeCatalogos") {
      data.Bodega = BodegaCatalogos;
    } else {
      data.Bodega = Bodega;
    }

    let pro = [];
    productosState[1] = pro;
    await axios
      .post(
        url,
        {
          data,
        },
        {}
      )
      .then(async (response) => {
        await modificarProductosState([
          { CambioSeccion: false, Busqueda: false, irInicio: true },
          response.data.data,
        ]);
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        if (errorState["Error"]) {
          modificarErrorState({
            Error: false,
            Mensaje: "Todo bien, todo correcto",
          });
        }
      })
      .catch(function (err) {
        if (!errorState["Error"])
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
      });
  };
  const cerrarFiltros = () => {
    modificarFiltrosState({
      Genero: filtrosState["Genero"],
      Categoria: filtrosState["Categoria"],
      CambioFiltro: false,
      CambioCategoria: false,
      filtroResumido: true,
      posicionFiltros: "-100%",
      width: "0px",
    });
  };

  //Cambia la categoria seleccionada en el state de filtros.
  const cambioGenero = (genero) => {
    limpiarFiltros();
    modificarFiltrosState({
      Genero: genero,
      Categoria: "NoNew",
      CambioFiltro: false,
      CambioCategoria: false,
      filtroResumido: false,
      posicionFiltros: "0%",
      width: "300px",
    });
  };

  function convertirPalabra(palabra) {
    // Convertir la primera letra en mayúscula y el resto en minúsculas
    palabra = palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase();
    
    return palabra;
  }

  //VERSION SELECCION EXCLUSIVA CATEGORIAS.
  //Recibe el evento de seleccion o deseleccion de las categorias de hombre y modifica su state
  const onChangeValueCategorias = (categoria, genero) => {
    // console.log("CATEGORIAAA: ", categoria.Categoria)
    // console.log("Genero: ", genero)
    if(Ruta !== '/Admin/GeneradorDeCatalogos'){// Ruta === '/'
      let auxcategoria = convertirPalabra(categoria.Categoria)
      if(genero === "Hombre" && auxcategoria === "Camiseta"){
        auxcategoria = "Camiseta"
      }
      if(auxcategoria === "Gorra"){
        genero = "Unisex"
      }
      if(auxcategoria === "Sueter"){
        auxcategoria = "Hoodie"
      }
      if(auxcategoria === "Pantaloneta"){
        auxcategoria = "Pantalonetas"
      }
      window.location.href = `/Mercado/${auxcategoria}/${genero}`
    }

    if (productosState[0]["Busqueda"]) {
      document.getElementById("Barra").value = "";
      document.getElementById("BarraFiltro").value = "";
    }
    limpiarFiltros();
    modificarCategoriasState({ ...categoriasState, [genero]: categoria._id });
    modificarListaTallasState(categoria.GrupoTallas.Tallas);
    modificarFiltrosState({
      Genero: Genero,
      Categoria: Categoria,
      CambioFiltro: true,
      CambioCategoria: false,
      filtroResumido: false,
      posicionFiltros: "-100%",
      width: "0px",
    });
  };

  //Recibe el evento de seleccion o deseleccion de las marcas y modifica su state
  const onChangeValueMarcas = (marcaId) => {
    if (productosState[0]["Busqueda"] !== false) {
      document.getElementById("Barra").value = "";
      document.getElementById("BarraFiltro").value = "";
      limpiarFiltros();
      cerrarFiltros();
    }
    let listaMarcaTemp = marcasState;
    if (comprobarMarcaSeleccionada(marcaId))
      listaMarcaTemp.splice(listaMarcaTemp.indexOf(marcaId), 1);
    else listaMarcaTemp.push(marcaId);
    modificarMarcasState(listaMarcaTemp);
    modificarFiltrosState({
      Genero: Genero,
      Categoria: Categoria,
      CambioFiltro: true,
      CambioCategoria: false,
      filtroResumido: false,
      posicionFiltros: "0%",
      width: "300px",
    });
  };

  // const [medidasState, modificarMedidasState] = useState([]);
  const onChangeValueMedidas = (medidaId) => {
    if (productosState[0]["Busqueda"] !== false) {
      document.getElementById("Barra").value = "";
      document.getElementById("BarraFiltro").value = "";
      limpiarFiltros();
      cerrarFiltros();
    }
    let listaMedidaTemp = medidasState;
    if (comprobarMedidaSeleccionada(medidaId))
      listaMedidaTemp.splice(listaMedidaTemp.indexOf(medidaId), 1);
    else listaMedidaTemp.push(medidaId);
    modificarMedidasState(listaMedidaTemp);
    modificarRefNoCatalogoState([]);
    modificarFiltrosState({
      Genero: Genero,
      Categoria: Categoria,
      CambioFiltro: true,
      CambioCategoria: false,
      filtroResumido: false,
      posicionFiltros: "0%",
      width: "300px",
    });
  };

  //Recibe el evento de seleccion o deseleccion de las tallas y modifica su state
  const onChangeValueTallas = (tallaId) => {
    if (productosState[0]["Busqueda"] !== false) {
      document.getElementById("Barra").value = "";
      document.getElementById("BarraFiltro").value = "";
      limpiarFiltros();
    }
    let listaTallasTemp = tallasState;
    if (comprobarTallaSeleccionada(tallaId))
      listaTallasTemp.splice(listaTallasTemp.indexOf(tallaId), 1);
    else listaTallasTemp.push(tallaId);
    modificarTallasState(listaTallasTemp);
    modificarFiltrosState({
      Genero: Genero,
      Categoria: Categoria,
      CambioFiltro: true,
      CambioCategoria: false,
      filtroResumido: false,
      posicionFiltros: "-0%",
      width: "300px",
    });
  };

  //Recibe el evento de seleccion o deseleccion de los colores y modifica su state
  const onChangeValueColores = (colorId) => {
    if (productosState[0]["Busqueda"] !== false) {
      document.getElementById("Barra").value = "";
      document.getElementById("BarraFiltro").value = "";
      limpiarFiltros();
    }
    let listaColoresTemp = coloresState;
    if (comprobarColorSeleccionado(colorId))
      listaColoresTemp.splice(listaColoresTemp.indexOf(colorId), 1);
    else listaColoresTemp.push(colorId);
    modificarColoresState(listaColoresTemp);
    modificarFiltrosState({
      Genero: Genero,
      Categoria: Categoria,
      CambioFiltro: true,
      CambioCategoria: false,
      filtroResumido: false,
      posicionFiltros: "-100%",
      width: "0px",
    });
  };

  const TipoCatalogo = (e, tipoC) => {
    modificarTypeCat({ tipo: tipoC });
  };

  const calcularDescuento = (precio, descuento) => {
    const precioInt = arreglarPrecio(precio);
    const descuentoAplicar = parseInt(descuento);
    return formatNumber(precioInt - precioInt * (descuentoAplicar / 100));
  };
  //Retorna el precio ingresado sin signos de peso ni puntos.
  const arreglarPrecio = (precio) => {
    var separacion = precio;
    if (precio.indexOf("$") !== -1) separacion = precio.split(" ")[1];
    var separacion2 = separacion.split(".");
    return parseInt(separacion2[0] + separacion2[1]);
  };

  const formatNumber = (amount) => {
    const total = Math.round(amount);

    var temp = total + "";
    var i = temp.length - 3;
    while (i > 0) {
      temp = temp.substring(0, i) + "." + temp.substring(i);
      i -= 3;
    }
    return " " + temp;
  };

  //Elimina del catalogo los productos no seleccionados
  const prepararCatalogo = () => {
    let catalogo = productosState[1];
    let filterCatalogo = catalogo;
    if (refNoCatalogoState.length > 0) {
      catalogo = productosState[1].filter((producto) => {
        let existe = refNoCatalogoState.find(
          (prodS) => prodS.Referencia === producto.Referencia
        );
        if (!existe) return producto;
      });
    }
    filterCatalogo = catalogo.filter(comprobarCantidades);
    return filterCatalogo;
  };

  /** Comprueba que la referencia tenga la cantidad minima disponible
   * para aparecer en el catálogo */
  const comprobarCantidades = (producto) => {
    let cantidad = 0;
    const tallas = producto.Variantes[0].Tallas;
    for(const talla of tallas) {
      cantidad += talla.Cantidad;
    }
    return (cantidad >= minUnidades) ? true : false;
  }

  const handleCheck = () => {
    let check = !checkedProductos;
    if(check) {
      setCheckedProductos(true);
      modificarRefNoCatalogoState([]);
    } else {
      setCheckedProductos(false);
      modificarRefNoCatalogoState(productosState[1]);
    }
  }

  const reduceImageQuality = async(imagePath, quality) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imagePath;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              const base64data = reader.result;
              resolve(base64data);
            };
          }, 'image/jpeg', quality);
        };
        img.onerror = (err) => reject(err);
      });
  }

  //Esta funcion permite generar catalogos dinamicos basados en los filtros de la ruta Catalogo-ventas
  const GenerarCatalogo = async () => {
    //Genero un nuevo objeto jsPDF para escribir sobre el
    const doc = new jsPDF();
    //Se crea la imagen del header

    let productosEnCatalogo = prepararCatalogo();
    var categoria = productosState[1][0].Categoria.Categoria;
    //(productosState[1][0].Categoria !== "BATA") ? productosState[1][0].Categoria : productosState[1][1].Categoria;
    const MESES = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    var fecha = new Date();
    var date =
      fecha.getDate() +
      "/" +
      MESES[fecha.getMonth()] +
      "/" +
      fecha.getFullYear();
    let Header = new Image();
    Header.src = "/img/General/Catalogo-basicas.png";
    let fit = "";
    medidasState.map((medida) => (fit += "-" + medida + " "));
    //Se definen las dimensiones del  header donde 210 es el ancho y 25 es el alto
    doc.setTextColor(255, 255, 255);
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(10);
    doc.addImage(Header, "PNG", 0, 0, 210, 25);
    doc.text("CATEGORIÁ: " + productosEnCatalogo[0].Categoria.Categoria, 5, 10);
    doc.text("GÉNERO: " + productosEnCatalogo[0].Genero.Genero, 5, 15);
    doc.text("FÍT: " + fit, 5, 20);
    doc.setFontSize(12);
    doc.text("Fecha: " + date, 155, 14);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(10);

    //las siguientes variables hacen referencia al alto, top y cambio de pagina respectivamente
    var top = 28;
    var left = 22.5;
    var right = 57;
    var pag = 0;
    //Se mapea la lista de imagenes que se van a insertar en el archivo pdf
    for(const producto of productosEnCatalogo) {
      var categoriaInterna = productosEnCatalogo[0].Categoria.Categoria;
      if (producto["Codigo"] !== "salta") {
        //Se toman las keys del json de tallas
        //var tallas = Object.keys(producto.productos[0].Cantidad);
        var tallas = [];
        producto.Variantes.forEach((variante) => {
          variante.Tallas.forEach((TallasD) => {
            if (TallasD.Cantidad > 0) {
              tallas.push(TallasD.Talla);
            }
          });
        });
        //var tallas = producto.Variantes[0].Tallas[0].Talla

        var lista = "";

        //Se verifica si el tipo de talla es s,m,l,xl
        if (
          tallas[0] === "L" ||
          tallas[0] === "M" ||
          tallas[0] === "S" ||
          tallas[0] === "XL" ||
          tallas[0] === "XXL"
        ) {
        }
        //tallas = ["S","M","L","XL","XXL"];

        //Se insertan las tallas en orden
        for (var i = 0; i < tallas.length; i++) {
          //if(producto.productos[0].Cantidad[tallas[i]] > 0){
          //lista = (lista === "") ? lista + tallas[i] : lista +" / "+ tallas[i];
          lista = lista === "" ? lista + tallas[i] : lista + " / " + tallas[i];
          //}
        }
        //se pagina el pdf cada 9 prendas
        if (pag === 9 || categoriaInterna !== categoria) {
          categoria = productosEnCatalogo[0].Categoria.Categoria;
          //categoria = "Hi";
          doc.addPage();
          doc.setFontSize(12);
          doc.setFont("Helvetica", "bold");
          doc.setTextColor(255, 255, 255);
          doc.addImage(Header, "PNG", 0, 0, 210, 25);
          doc.text("CATALOGO", 5, 14);
          doc.text("Fecha: " + date, 155, 14);
          doc.setTextColor(0, 0, 0);
          top = 28;
          left = 22.5;
          pag = 0;
        }
        pag = pag + 1;

        let pruebaImg = new Image();
        doc.setFontSize(10);
        pruebaImg.src = "/img/Productos/Ref " + producto.Referencia + ".jpg";
        const imageData = await reduceImageQuality(pruebaImg.src, 0.7);
        doc.addImage(imageData, "JPEG", left, top + 5, 40, 62);
        doc.setFont("Helvetica", "bold");
        doc.text("Ref " + producto.Referencia, left, top + 72);
        doc.setFont("Helvetica", "");
        doc.text("Tallas disponibles: " + lista, left, top + 77);
        if (TypeCat.tipo === "Mayorista") {
          if (producto["DescuentoMayorista"] === 0) {
            doc.text(
              "Mayorista: $" + producto["PrecioMayorista"],
              left,
              top + 82
            );
          } else {
            doc.setTextColor(255, 0, 0);
            doc.text(
              "Mayorista -" + producto.DescuentoMayorista + "% DTO",
              left,
              top + 82
            );
            doc.text(
              "$" +
                calcularDescuento(
                  producto.PrecioMayorista,
                  producto.DescuentoMayorista
                ),
              left,
              top + 87
            );
            doc.setTextColor(0, 0, 0);
          }
        } else if (TypeCat.tipo === "Minorista") {
          if (producto.DescuentoMinorista === 0) {
            doc.text(
              "Unidad: $" + producto["PrecioMinorista"], // se quita el formatNumber()
              left,
              top + 82
            );
          } else {
            doc.setTextColor(255, 0, 0);
            doc.text(
              "Unidad -" + producto.DescuentoMinorista + "% DTO",

              left,
              top + 82
            );
            doc.text(
              "$" +
                calcularDescuento(
                  producto.PrecioMinorista,
                  producto.DescuentoMinorista
                ),
              left,
              top + 87
            );
            doc.setTextColor(0, 0, 0);
          }
          ///doc.text("Unidad: "+producto["PrecioMinorista"], left, top+77);
        } else if (TypeCat.tipo === "Todo") {
          if (producto["DescuentoMayorista"] === 0) {
            doc.text(
              "Mayorista: $" + producto["PrecioMayorista"],
              left,
              top + 82
            );
          } else {
            doc.setTextColor(255, 0, 0);
            doc.text(
              "Mayorista -" + producto.DescuentoMayorista + "% DTO",
              left,
              top + 82
            );
            doc.text(
              "$" +
                calcularDescuento(
                  producto.PrecioMayorista,
                  producto.DescuentoMayorista
                ),
              right,
              top + 82
            );
            doc.setTextColor(0, 0, 0);
          }
          if (producto.DescuentoMinorista === 0) {
            doc.text("Unidad: $" + producto["PrecioMinorista"], left, top + 87);
          } else {
            doc.setTextColor(255, 0, 0);
            doc.text(
              "Unidad -" + producto.DescuentoMinorista + "% DTO",

              left,
              top + 87
            );
            doc.text(
              "$" +
                calcularDescuento(
                  producto.PrecioMinorista,
                  producto.DescuentoMinorista
                ),
              right,
              top + 87
            );
            doc.setTextColor(0, 0, 0);
          }
        } else {
        }

        //doc.text("Mayorista: "+producto["PrecioMinorista"], left, top+77);
        //doc.text("Mayorista: "+producto["PrecioMayorista"], left, top+77);
        //doc.text("Unidad: "+producto["PrecioMinorista"], left, top+82);

        //Se reinician las dimensiones al cambiar de linea
        if (left === 147.5) {
          left = 22.5;
          top = top + 87;
        } else {
          left = left + 62.5;
        }
        if (right === 182) {
          right = 57;
        } else {
          right = right + 62.5;
        }
      }
    };
    //Genera el archivo pdf descargable
    doc.save(
      "CATÁLOGO-" +
        productosState[1][0].Categoria.Categoria +
        " " +
        productosState[1][0].Genero.Genero +
        fit +
        " " +
        TypeCat.tipo +
        ".pdf"
    );
    //doc.save("Catalogo-"+categoria+"S.pdf");
  };

  //Al iniciar el componente se pregunta si hay cambios de categoria o en los filtros para traer la lista de productos.
  useEffect(() => {
    if (CambioFiltro) {
      cambioMatriz(Categoria, Genero);
    }
    if (
      listaCategoriasState["Hombre"].length === 0 ||
      listaCategoriasState["Mujer"].length === 0 
      // ||      listaCategoriasState["Niño"].length === 0
    )
      obtenerCategoriasApi();
    if (listaMarcasState.length === 0) obtenerMarcasApi();
    if (listaColoresState.length === 0) obtenerColoresApi();
    if (listaGenerosState.length === 0) obtenerGenerosApi();
    if (
      listaMedidasState["Hombre"].length === 0 ||
      listaMedidasState["Mujer"].length === 0
    )
      obtenerMedidas();
  },[CambioFiltro]);

  const obtenerCatalogo = () => {
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Generando PDF...",
      });
    }
    let productosEnCatalogo = prepararCatalogo();
    var categoria = productosState[1][0].Categoria.Categoria;
    // console.log("productosEnCatalogo: ", productosEnCatalogo)
    axios
      .post(apiUrlState + "/Api/Catalogo/catalogos", {
        data: {
          productosEnCatalogo: productosEnCatalogo,
          medidas: medidasState,
          TypeCat: TypeCat,
          categoria: categoria,
          genero: productosState[1][0].Genero.Genero,
          unidades: unidades,
        },
      })
      .then((response) => {
        if (true) {
          const linkSource = `data:application/pdf;base64,${response.data.catalog}`
          const downloadLink = document.createElement("a")
          const fileName = "CATÁLOGO-" +
          productosState[1][0].Categoria.Categoria +
          " " +
          productosState[1][0].Genero.Genero +
          " " +
          TypeCat.tipo
          

          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
        modificarSpinnerState({
          Desplegar: false,
          Texto: "Descargando Catalogo...",
        });
      })
      .catch(function (err) {
        if (!errorState["Error"]) {
          // modificarErrorState({
          //   Error: true,
          //   Mensaje: "Error al recuperar datos del servidor",
          // });
        }
        modificarSpinnerState({
          Desplegar: false,
          Texto: "Descargando Catalogo...",
        });
        console.log("Error: ", err)
      });
  };


  return (
    <>
      {window.location.pathname == "/Admin/GeneradorDeCatalogos"? (
      <div>
        <div className="filaMarca">
          <p
            className="FuentePoppinsB"
            style={{
              color: "'#000000",
              fontSize: "1.2rem",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          >
            Bodega
          </p>
        </div>
        <div className="filaNew">
          <button
            className="FuentePoppinsL"
            id="genero"
            style={{ cursor: "pointer", fontSize:"1rem" }}
            onClick={() => {
              localStorage.setItem("BodegaCatalogos", "3");
              window.location.reload();
            }}
          >
            Principal
          </button>
          {/* <button
            className="FuentePoppinsL"
            id="genero"
            style={{ cursor: "pointer", marginLeft: "10px", fontSize:"1rem" }}
            onClick={() => {
              localStorage.setItem("BodegaCatalogos", "6");
              window.location.reload();
            }}
          >
            Cali
          </button> */}
          <button
            className="FuentePoppinsL"
            id="genero"
            style={{ cursor: "pointer", marginLeft: "10px", fontSize:"1rem" }}
            onClick={() => {
              localStorage.setItem("BodegaCatalogos", "13");
              window.location.reload();
            }}
          >
            Preventa
          </button>
        </div>

      </div>

      ) : null }
      <div className="filaNew" style={Ruta  === '/' ? {marginTop:"90px"} : null}>
        <button
          className="FuentePoppinsB"
          id="genero"
          style={{ cursor: "pointer" }}
          onClick={() => /*Ruta  !== '/' ? limpiarFiltros() :*/  window.location.href = "/Mercado/Nuevo/New"}
        >
          Nuevo
        </button>
      </div>
      <div className="filaNew">
        <button
          className="FuentePoppinsItalicB"
          id="genero"
          style={{ cursor: "pointer", color: "#c0272d" }}
          onClick={() => Ruta  !== '/' ? limpiarFiltros2() :   window.location.href = "/Mercado/Descuento/Todo" }
        >
          Rebajas🔥
        </button>
      </div>
      
      <div className="filaGenero">
        {listaGenerosState.map((genero) =>
          genero.Estado ? (
            <button
              key={genero._id}
              className="FuentePoppinsB"
              id="genero"
              style={{ cursor: "pointer", marginRight: "50px" }}
              onClick={() => cambioGenero(genero._id)}
            >
              {capitalizarPrimeraLetra(genero.Genero.toLowerCase())}
            </button>
          ) : null
        )}
      </div>
      
      <div className="columnasCategorias">
        {Genero === "607ed653521b1c0910a581b8" ? (
          <div style={{ marginLeft: "0.5em" }}>
            {listaCategoriasState["Hombre"].map((categoria) => (
              <div key={categoria._id + "Hombre"}>
                <button
                  href="#"
                  className="FuentePoppinsL categorias SinDecoracionFiltros text-left"
                  id={categoria.Categoria}
                  style={
                    comprobarCategoriaSeleccionada(categoria._id, "Hombre")
                      ? seleccionado
                      : {}
                  }
                  onClick={() => onChangeValueCategorias(categoria, "Hombre")}
                >
                  {capitalizarPrimeraLetra(categoria.Categoria.toLowerCase())}s
                </button>
              </div>
            ))}
          </div>
        ) : Genero === "607ed653521b1c0910a581b9" ? (
          <div
            style={{
              marginLeft: "8.4em",
              textAlign: "left",
            }}
          >
            {listaCategoriasState["Mujer"].map((categoria) => (
              <div key={categoria._id + "Mujer"}>
                <button
                  href="#"
                  className="FuentePoppinsL categorias SinDecoracionFiltros"
                  id={categoria.Categoria}
                  style={
                    comprobarCategoriaSeleccionada(categoria._id, "Mujer")
                      ? seleccionado
                      : {}
                  }
                  onClick={() => onChangeValueCategorias(categoria, "Mujer")}
                >
                  {capitalizarPrimeraLetra(categoria.Categoria.toLowerCase())}s
                </button>
              </div>
            ))}
          </div>
        ) : Genero === "607ed653521b1c0910a581ba" ? (
          <div style={{ marginLeft: "60%" }}>
            {listaCategoriasState["Niño"].map((categoria) => (
              <div key={categoria._id + "Niño"}>
                <button
                  href="#"
                  className="FuenteBebas categorias SinDecoracionFiltros text-left"
                  id={categoria.Categoria}
                  style={
                    comprobarCategoriaSeleccionada(categoria._id, "Niño")
                      ? seleccionado
                      : {}
                  }
                  onClick={() => onChangeValueCategorias(categoria, "Niño")}
                >
                  {categoria.Categoria}s
                </button>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      { 
        Ruta !== '/'? 
        <>
        <div>
        <hr className="lineaFiltros" />
      </div>
      <div className="filaMarca">
        <p
          className="FuentePoppinsB"
          style={{
            color: "'#000000",
            fontSize: "1.2rem",
            marginTop: "0px",
            marginBottom: "0px",
          }}
        >
          Marcas
        </p>
      </div>
      <div className="columnasMarcas">
        <div>
          {listaMarcasState.map((marca) =>
            marca.Estado ? (
              <div key={marca._id}>
                <button
                  className="FuentePoppinsL marcas"
                  id={marca.Marca}
                  style={
                    comprobarMarcaSeleccionada(marca._id) ? seleccionado : {}
                  }
                  onClick={() => onChangeValueMarcas(marca._id)}
                >
                  {capitalizarPrimeraLetra(marca.Marca.toLowerCase())}
                </button>
              </div>
            ) : null
          )}
        </div>
      </div>
      <div>
        <hr className="lineaFiltros" />
      </div>
      <div className="filaMarca">
        <p
          className="FuentePoppinsB"
          style={{
            color: "'#000000",
            fontSize: "1.2rem",
            marginTop: "0px",
            marginBottom: "0px",
          }}
        >
          Fit
        </p>
      </div>
      <div className="columnasMarcas">
        {Genero === "607ed653521b1c0910a581b8"
          ? listaMedidasState["Hombre"].map((medida) => (
              <div key={medida._id}>
                <button
                  className="FuentePoppinsL marcas"
                  id={medida.Medida}
                  style={
                    comprobarMedidaSeleccionada(medida._id) ? seleccionado : {}
                  }
                  onClick={() => onChangeValueMedidas(medida._id)}
                >
                  {capitalizarPrimeraLetra(medida.Medida.toLowerCase())}
                </button>
              </div>
            ))
          : Genero === "607ed653521b1c0910a581b9"
          ? listaMedidasState["Mujer"].map((medida) => (
              <div key={medida._id}>
                <button
                  className="FuentePoppinsL marcas"
                  id={medida.Medida}
                  style={
                    comprobarMedidaSeleccionada(medida._id) ? seleccionado : {}
                  }
                  onClick={() => onChangeValueMedidas(medida._id)}
                >
                  {capitalizarPrimeraLetra(medida.Medida.toLowerCase())}
                </button>
              </div>
            ))
          : null}
      </div>
      <div>
        <hr className="lineaFiltros" />
      </div>
      <div className="filaFiltros">
        <p
          className="FuentePoppinsB"
          style={{
            color: "'#000000",
            fontSize: "1.2rem",
            marginTop: "0px",
            marginBottom: "0px",
          }}
        >
          Tallas
        </p>
      </div>
      <div className="filaTallas">
        {listaTallasState.map((talla) => (
          <img
            key={talla.Talla._id}
            className="bola"
            src={
              recursosUrlState +
              "/img/Filtros/" +
              talla.Talla.Nombre +
              (comprobarTallaSeleccionada(talla.Talla.Nombre)
                ? "-negro.webp"
                : "-blanco.webp")
            }
            id={talla.Talla.Nombre}
            onClick={() => onChangeValueTallas(talla.Talla.Nombre)}
            alt={talla.Talla.Nombre}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Filtros/" +
                talla.Talla.Nombre +
                (comprobarTallaSeleccionada(talla.Talla.Nombre)
                  ? "-negro.png"
                  : "-blanco.png");
            }}
          />
        ))}
      </div>
      <div>
        <hr className="lineaFiltros" />
      </div>
      <div className="filaFiltros">
        <p
          className="FuentePoppinsB"
          style={{
            color: "#000000",
            fontSize: "1.2rem",
            marginTop: "0px",
            marginBottom: "10px",
          }}
        >
          Colores
        </p>
      </div>
      <div className="filaColores">
        {listaColoresState.map((color) => (
          <button
            key={color._id}
            className={
              comprobarColorSeleccionado(color._id)
                ? color.Color === "NEGRO"
                  ? "boton_personalizado SinDecoracion bola strikeBlanco"
                  : "boton_personalizado SinDecoracion bola strikeNegro"
                : " boton_personalizado SinDecoracion bola"
            }
            value={color.Color}
            style={{
              backgroundColor: color.Hexadecimal,
              border: color.Color === "BLANCO" ? "solid 0.5px" : "",
            }}
            onClick={() => onChangeValueColores(color._id)}
            title={color.Color}
          ></button>
        ))}
      </div>
      <div style={{display: "flex", justifyContent: "space-evenly", marginTop: "20px", marginBottom: "50px"}}>
        <p
          className="botonLimpiarFiltrosF text-center FuentePoppinsL"
          style={{ cursor: "pointer" }}
          onClick={() => cerrarFiltros()}
        >
          Aplicar filtros
        </p>
      
        <p
          className="botonLimpiarFiltrosF text-center FuentePoppinsL"
          style={{ cursor: "pointer" }}
          onClick={() => limpiarFiltros()}
        >
          Borrar filtros
        </p>
      </div></> : null
      }
      
      {CatalogoState || headerState["BotonCatalogo"] ? (
        <>
          <div
            style={{
              display: "flex",
              marginLeft: "38px",
              flexDirection: "column",
            }}
          >
            <div className="form-check" style={{ margin: "10px" }}>
              <input
                style={{ marginTop: "12px" }}
                className="form-check-input "
                type="radio"
                name="filtroRadios"
                id="check1"
                onClick={(e) => TipoCatalogo(e, "Mayorista")}
              />
              <label
                className="form-check-label"
                htmlFor="check1"
                style={{
                  fontSize: "24px",
                  fontFamily: "Bebas",
                  paddingLeft: "20px",
                }}
              >
                Mayorista
              </label>
            </div>
            <div className="form-check" style={{ margin: "10px" }}>
              <input
                style={{ marginTop: "12px" }}
                className="form-check-input "
                type="radio"
                name="filtroRadios"
                id="check2"
                onClick={(e) => TipoCatalogo(e, "Minorista")}
              />
              <label
                className="form-check-label"
                htmlFor="check2"
                style={{
                  fontSize: "24px",
                  fontFamily: "Bebas",
                  paddingLeft: "20px",
                }}
              >
                Minorista
              </label>
            </div>
            <div className="form-check" style={{ margin: "10px" }}>
              <input
                style={{ marginTop: "12px" }}
                className="form-check-input "
                type="radio"
                name="filtroRadios"
                id="check3"
                onClick={(e) => TipoCatalogo(e, "Todo")}
              />
              <label
                className="form-check-label"
                htmlFor="check3"
                style={{
                  fontSize: "24px",
                  fontFamily: "Bebas",
                  paddingLeft: "20px",
                }}
              >
                Ambos
              </label>
            </div>
            <div className="form-check" style={{ margin: "10px" }}>
              <input
                style={{ marginTop: "12px" }}
                className="form-check-input "
                type="radio"
                name="filtroRadios"
                id="check4"
                onClick={(e) => TipoCatalogo(e, "SinPrecio")}
              />
              <label
                className="form-check-label"
                htmlFor="check4"
                style={{
                  fontSize: "24px",
                  fontFamily: "Bebas",
                  paddingLeft: "20px",
                }}
              >
                Sin precio
              </label>
            </div>            
            <hr/>
            <div className="form-check" style={{ margin: "10px" }}>
              <input
                style={{ marginTop: "12px", accentColor: "black"}}
                className="form-check-input "
                type="checkbox"
                name="filtroUnidades"
                id="check5"
                onClick={(e) => setUnidades(!unidades)}
              />
              <label
                className="form-check-label"
                htmlFor="check5"
                style={{
                  fontSize: "24px",
                  fontFamily: "Bebas",
                  paddingLeft: "20px",
                }}
              >
                Cantidades
              </label>
            </div>
            <div className="form-check" style={{ margin: "10px" }}>
              <input
                style={{ marginTop: "12px", accentColor: "black"}}
                className="form-check-input "
                type="checkbox"
                id="check6"
                checked={checkedProductos}
                onClick={(e) => handleCheck()}
              />
              <label
                className="form-check-label"
                htmlFor="check6"
                style={{
                  fontSize: "24px",
                  fontFamily: "Bebas",
                  paddingLeft: "20px",
                }}
              >
                Seleccionar
              </label>
            </div>
            <div className="form-check" 
              style={{ margin: "10px", display: "flex" }}>
              <input type="number" name="" id="unidades"
                style={{ accentColor: "black", maxWidth: "35px", border: "solid 2px" }}
                className="form-check-input"
                value={minUnidades}
                onChange={(e) => setMinUnidades(e.target.value)}
              />
              <label htmlFor="unidades"
                className="form-check-label"
                style={{
                  fontSize: "24px",
                  fontFamily: "Bebas",
                  paddingLeft: "20px",
                }}
              >
                Min Unidades
              </label>
            </div>
          </div>
          {/* <p
            className="botonLimpiarFiltros text-center FuenteBebas"
            style={{ cursor: "pointer" }}
            onClick={() => GenerarCatalogo()}
          >
            Generar Catalogo
          </p> */}
          <p
            className="botonLimpiarFiltros text-center FuenteBebas"
            style={{ cursor: "pointer" }}
            onClick={() => obtenerCatalogo()}
          >
            Generar Catalogo
          </p>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Filtros;
