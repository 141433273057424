import React, { useEffect, useState } from "react";
import axios from "axios";
import "../static/Indicadores.css";
import { useParams, useHistory } from "react-router-dom";

const Rotacion = ({ SetLandingHeader, apiUrlState, recursosUrlState }) => {

  const token = localStorage.getItem("token");
  const history = useHistory();
  useEffect(() => {
    if (token) {
      
    }else{
      history.push("/login")
    }
    //console.log(reports)
  },[token]);

  let { id } = useParams();
  const VolverAdmin = () => {
    window.location.href = "/Admin/Rotacion/Seller/" + Periodo;
  };
  const [LRotacion, GetListaRotacion] = useState([
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
    {
      grupo: "PRUEBA",
      Final: 0,
      vendidas: 0,
      disponible: 0,
      rotacion: 0,
      VendidasDafiti: 0,
      VendidasMercado: 0,
      VendidasLinio: 0,
      VendidasExito: 0,
      Devoluciones: 0,
      VendidasTotal: 0,
    },
  ]);
  const [Periodo, GetPeriodo] = useState("PRUEBA");
  const [Costo, GetCosto] = useState(0);
  const [Venta, GetVenta] = useState(0);
  const [Marketplace, GetMarketplace] = useState("PRUEBA");
  const [Totales, SetTotales] = useState([0,0,0,0,0,0,0,0,0,0]);

  const Rotacion = async () => {
    await axios
      .post(apiUrlState + "/Api/Unidades/RotacionUnidades", {
        _id: id,
      })
      .then((response) => {
        GetMarketplace(response.data.data[0].Markeplace);
        GetCosto(response.data.data[0].costo);
        GetVenta(response.data.data[0].venta);
        GetPeriodo(response.data.data[0].Periodo);
        GetListaRotacion(response.data.data[0].data);
      })
      .catch(function (err) {
        console.log("Error");
      });
  };

  const totales = ()=>{
    let inventarioInicial = 0;
    let devoluciones = 0;
    let ventasUnidadesCroatta = 0;
    let ventasDafiti = 0;
    let ventasLinio = 0;
    let ventasMercado = 0;
    let ventasExito = 0;
    let ventasTotal = 0;
    let final = 0;
    let rotacion = 0;
    let contadorR = 0;
    for(const grupo of LRotacion) {
      // console.log(grupo, grupo.VendidasTotal);
      inventarioInicial += grupo.disponible;
      devoluciones += grupo.Devoluciones;
      ventasUnidadesCroatta += grupo.vendidas;
      ventasDafiti += grupo.VendidasDafiti;
      ventasLinio += grupo.VendidasLinio;
      ventasMercado += grupo.VendidasMercado;
      ventasExito+= grupo.VendidasExito;
      ventasTotal += grupo.VendidasTotal;
      final += grupo.Final;
      // rotacion += grupo.rotacion;
      if(grupo.rotacion > 0) {
        contadorR += 1;
        rotacion += grupo.rotacion;
      }
    }
    // let mediaR = rotacion / LRotacion.length;
    let mediaR = parseInt(rotacion / contadorR);
    SetTotales([
      inventarioInicial,
      devoluciones,
      ventasUnidadesCroatta,
      ventasDafiti,
      ventasLinio,
      ventasMercado,
      ventasExito,
      ventasTotal,
      final,
      mediaR,
    ]);
  }

  const formatterPeso = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
  });

  useEffect(() => {
    SetLandingHeader({ Estado: true });
    Rotacion();
  }, []);

  useEffect(()=>{
    totales();
  }, [LRotacion]);

  return (
    <div>
      <h1
        style={{ marginTop: "150px", textAlign: "center", fontWeight: "bold" }}
      >
        Rotación De Unidades{" "}
        <p>
          {Periodo} {Marketplace}
        </p>
      </h1>

      <div className="container">
        <div className="table-responsive">
          <table className="table">
            {Marketplace === "CROATTA" ? (
              <>
                <thead style={{ marginTop: "50px", textAlign: "center" }}>
                  <tr>
                    <th
                      rowSpan="2"
                      colSpan="1"
                      scope="col"
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      Linea
                    </th>

                    <th
                      colSpan="1"
                      rowSpan="2"
                      scope="col"
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      Inventario inicial
                    </th>
                    <th
                      colSpan="1"
                      rowSpan="2"
                      scope="col"
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      Devoluciones
                    </th>
                    <th
                      colSpan="6"
                      rowSpan="1"
                      scope="col"
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      Vendidas
                    </th>
                    <th
                      colSpan="1"
                      rowSpan="2"
                      scope="col"
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      Inventario final
                    </th>

                    <th
                      rowSpan="2"
                      colSpan="1"
                      scope="col"
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                      align="center"
                    >
                      Rotacion
                    </th>
                  </tr>

                  <tr>
                    <td
                      scope="col"
                      style={{ fontWeight: "bold", fontSize: "15px" }}
                    >
                      CROATTA
                    </td>
                    <td
                      scope="col"
                      style={{ fontWeight: "bold", fontSize: "15px" }}
                    >
                      DAFITI
                    </td>
                    <td
                      scope="col"
                      style={{ fontWeight: "bold", fontSize: "15px" }}
                    >
                      LINIO
                    </td>
                    <td
                      scope="col"
                      style={{ fontWeight: "bold", fontSize: "15px" }}
                    >
                      MERCADOLIBRE
                    </td>
                    <td
                      scope="col"
                      style={{ fontWeight: "bold", fontSize: "15px" }}
                    >
                      EXITO
                    </td>
                    <td
                      scope="col"
                      style={{ fontWeight: "bold", fontSize: "15px" }}
                    >
                      TOTAL
                    </td>
                  </tr>
                </thead>
                <tbody>
                {LRotacion.map((elemento, index) => (
                  <tr style={{ textAlign: "center" }} key={index}>
                    <th>{elemento.grupo}</th>
                    <th>{elemento.disponible}</th>
                    <th>{elemento.Devoluciones}</th>
                    <th>{elemento.vendidas}</th>
                    <th>{elemento.VendidasDafiti}</th>
                    <th>{elemento.VendidasLinio}</th>
                    <th>{elemento.VendidasMercado}</th>
                    <th>{elemento.VendidasExito}</th>
                    <th>{elemento.VendidasTotal}</th>
                    <th>{elemento.Final}</th>
                    <th>{elemento.rotacion}%</th>
                  </tr>
                ))}
                <tr style={{ textAlign: "center" }}>
                  <th style={{ fontWeight: "bold", fontSize: "15px" }}>Totales</th>
                  <th style={{ fontWeight: "bold", fontSize: "15px" }}>{Totales[0]}</th>
                  <th style={{ fontWeight: "bold", fontSize: "15px" }}>{Totales[1]}</th>
                  <th style={{ fontWeight: "bold", fontSize: "15px" }}>{Totales[2]}</th>
                  <th style={{ fontWeight: "bold", fontSize: "15px" }}>{Totales[3]}</th>
                  <th style={{ fontWeight: "bold", fontSize: "15px" }}>{Totales[4]}</th>
                  <th style={{ fontWeight: "bold", fontSize: "15px" }}>{Totales[5]}</th>
                  <th style={{ fontWeight: "bold", fontSize: "15px" }}>{Totales[6]}</th>
                  <th style={{ fontWeight: "bold", fontSize: "15px" }}>{Totales[7]}</th>
                  <th style={{ fontWeight: "bold", fontSize: "15px" }}>{Totales[8]}</th>
                  <th style={{ fontWeight: "bold", fontSize: "15px" }}>{Totales[9]}%</th>
                </tr>
                </tbody>
              </>
            ) : (
              <>
                <thead style={{ marginTop: "50px", textAlign: "center" }}>
                  <tr>
                    <th
                      rowSpan="1"
                      colSpan="1"
                      scope="col"
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      Linea
                    </th>

                    <th
                      colSpan="1"
                      rowSpan="1"
                      scope="col"
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      Inventario inicial
                    </th>
                    <th
                      colSpan="1"
                      rowSpan="1"
                      scope="col"
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      Vendidas
                    </th>
                    <th
                      colSpan="1"
                      rowSpan="1"
                      scope="col"
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      Inventario final
                    </th>

                    <th
                      rowSpan="1"
                      colSpan="1"
                      scope="col"
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                      align="center"
                    >
                      Rotacion
                    </th>
                  </tr>
                </thead>
                <tbody>
                {LRotacion.map((elemento, index) => (
                  <tr style={{ textAlign: "center" }} key={index}>
                    <th>{elemento.grupo}</th>
                    <th>{elemento.disponible}</th>
                    <th>{elemento.vendidas}</th>
                    <th>{elemento.Final}</th>
                    <th>{elemento.rotacion}%</th>
                  </tr>
                ))}
                <tr style={{ textAlign: "center" }}>
                  <th style={{ fontWeight: "bold", fontSize: "15px" }}>Totales</th>
                  <th style={{ fontWeight: "bold", fontSize: "15px" }}>{Totales[0]}</th>
                  <th style={{ fontWeight: "bold", fontSize: "15px" }}>{Totales[2]}</th>
                  <th style={{ fontWeight: "bold", fontSize: "15px" }}>{Totales[8]}</th>
                  <th style={{ fontWeight: "bold", fontSize: "15px" }}>{Totales[9]}%</th>
                </tr>
                </tbody>
              </>
            )}
          </table>
        </div>
        <div>
          <h3
            style={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Costo: {formatterPeso.format(Costo)}
          </h3>
          <h3
            style={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Venta: {formatterPeso.format(Venta)}
          </h3>
          <h3
            style={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Utilidad: {formatterPeso.format(Venta+Costo)}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Rotacion;
