//Zona de importacion de componentes externos.
import React, { useEffect, useState } from "react";
import { Link, useHistory  } from "react-router-dom";
import axios from "axios";
//import { useNavigate } from "react-router-dom";
//Zona de importacion de componentes internos.
import Registro from "./Registro";
import { useGlobalContext } from "../../providers/GlobalStatesProviders";

const InicioSesion = ({
  headerState,
  modificarHeaderState,
  recursosUrlState,
  modificarRecursosUrlState,
  apiUrlState,
  modificarApiUrlState,
  clienteState,
  modificarClienteState,
  spinnerState,
  modificarSpinnerState,
  errorState,
  modificarErrorState,
  pedidoState,
  modificarPedidoState,
  administradorState,
  modificarAdministradorState,
  actualizarSessionStorage,
}) => {
  // const { clienteState, modificarClienteState } = useGlobalContext();

  // states
  const [inputs, setInputs] = useState({ email: "", password: "" });
  const [mensaje, setMensaje] = useState();
  const [loading, setLoading] = useState(false);

  const { email, password } = inputs;

  const HandleChange = (e) => {
    console.log("[e.target.name]", e.target.name)
    console.log("[e.target.value]", e.target.value)
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const history = useHistory();

  const onSubmit = async (e) => {
    console.log("ENTRA CLICK", e.email, password )
    e.preventDefault();
    if (email !== "" && password !== "") {
      const Usuario = {
        email,
        password,
      };
      setLoading(true);
      await axios
        .post(apiUrlState+"/Api/auth/signin", Usuario)
        .then((res) => {
          const { data } = res;
          setMensaje(data.mensaje);
          setTimeout(() => {
            setMensaje("");
            
            localStorage.setItem("token", data?.token);
            history.push('/Admin/Indicadores')
            //navigate(`/welcome`);
          }, 1500);
        })
        .catch((error) => {
          console.error(error);
          setMensaje("email u password incorrecta");
          setTimeout(() => {
            setMensaje("");
          }, 1500);
        });
      setInputs({ email: "", password: "" });
      setLoading(false);
    }
  };
  //retorna el string recibido con la primera letra mayuscula.
  const capitalizarPalabras = (val) => {
    var minuscula = val.toLowerCase();
    return val !== "" ? minuscula[0].toUpperCase() + minuscula.slice(1) : "";
  };

  //Funcion principal:
  //Esta funcion revisa las credenciales ingresadas, si estas son correctas realiza el inicio de sesion del cliente,
  //actualizando el state de Cliente y tambien el localStorage.
  const ingresar = async () => {
    let usuario = document.getElementById("usuario_input").value;
    let contraseña = document.getElementById("contraseña_input").value;
    if (usuario === "")
      modificarErrorState({
        Error: true,
        Mensaje: "El ingreso del correo es obligatorio",
      });
    else if (contraseña === "")
      modificarErrorState({
        Error: true,
        Mensaje: "El ingreso de la contraseña es obligatorio",
      });
    else {
      await ConsultarUsuario(contraseña, function (respuesta) {
        if (respuesta["Existe"]) {
          if (respuesta["Email"].toLowerCase() === usuario.toLowerCase()) {
            if (respuesta["Documento"] !== "1116283149") {
              actualizarSessionStorage(
                "Cliente",
                JSON.stringify({
                  Registro: false,
                  Accion: "Activo",
                  Sesion: true,
                  TipoDocumento: respuesta["TipoDocumento"],
                  NumeroDocumento: respuesta["NumeroDocumento"],
                  Nombres: capitalizarPalabras(respuesta["Nombres"]),
                  Apellidos: capitalizarPalabras(respuesta["Apellidos"]),
                  Correo: respuesta["Correo"],
                  Genero: respuesta["Genero"],
                  Celular: respuesta["Celular"],
                  FechaNacimiento: respuesta["FechaNacimiento"],
                  Pais: respuesta["Pais"],
                  Ciudad: respuesta["Ciudad"],
                  Departamento: respuesta["Departamento"],
                  Direccion: respuesta["Direccion"],
                  Barrio: respuesta["Barrio"],
                })
              );
              modificarClienteState({
                Registro: false,
                Accion: "Activo",
                Sesion: true,
                TipoDocumento: respuesta["TipoDocumento"],
                NumeroDocumento: respuesta["NumeroDocumento"],
                Nombres: capitalizarPalabras(respuesta["Nombres"]),
                Apellidos: capitalizarPalabras(respuesta["Apellidos"]),
                Correo: respuesta["Correo"],
                Genero: respuesta["Genero"],
                Celular: respuesta["Celular"],
                FechaNacimiento: respuesta["FechaNacimiento"],
                Pais: respuesta["Pais"],
                Ciudad: respuesta["Ciudad"],
                Departamento: respuesta["Departamento"],
                Direccion: respuesta["Direccion"],
                Barrio: respuesta["Barrio"],
              });
            } else {
              actualizarSessionStorage(
                "Administrador",
                JSON.stringify({
                  Recuperar: false,
                  NumeroDocumento: respuesta["NumeroDocumento"],
                  Nombrse: capitalizarPalabras(respuesta["Nombres"]),
                  Apellidos: capitalizarPalabras(respuesta["Apellidos"]),
                  Tipo: "Vendedor",
                })
              );
              modificarAdministradorState({
                Recuperar: false,
                NumeroDocumento: respuesta["NumeroDocumento"],
                Nombres: capitalizarPalabras(respuesta["Nombres"]),
                Apellidos: capitalizarPalabras(respuesta["Apellidos"]),
                Tipo: "Vendedor",
              });
            }

            window.location.href = "/";
          } else {
            modificarErrorState({ Error: true, Mensaje: "Correo incorrecto" });
          }
        } else {
          modificarErrorState({
            Error: true,
            Mensaje:
              "El usuario no existe o la contraseña es incorrecta (Recuerda que tu contraseña es tu numero de cedula, sin ningun caracter especial)",
          });
        }
      });
    }
  };

  //Esta funcion retorna true si todos los campos del clienteState estan llenos, false si no.
  const revisionDatosUsuarios = () => {
    for (const dato in clienteState)
      if (clienteState[dato] === "") return false;
    return true;
  };

  //Funcion principal:
  //Crea a un usuario en la base de datos de la pagina y de contapyme, segun los datos obtenidos y deja la sesion iniciado con este.
  const crearUsuario = async (callback) => {
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Creandote en nuestra base de datos...",
      });
    }
    await getJWT(async function (token) {
      await axios
        .post(
          apiUrlState + "/Usuario/CrearUsuarioReact",
          {
            TipoDocumento: clienteState["TipoDocumento"],
            NumeroDocumento: clienteState["NumeroDocumento"],
            Nombres: clienteState["Nombres"],
            Apellidos: clienteState["Apellidos"],
            Correo: clienteState["Correo"],
            Genero: clienteState["Genero"],
            Celular: clienteState["Celular"],
            Nacimiento: clienteState["Nacimiento"],
            Pais: clienteState["Pais"],
            Ciudad: clienteState["Ciudad"],
            Departamento: clienteState["Departamento"],
            Direccion: clienteState["Direccion"],
            Barrio: clienteState["Barrio"],
          },
          {
            headers: {
              authorization: "Bearer " + token["token"],
            },
          }
        )
        .then((response) => {
          if (response.data.Creado) {
            if (errorState["Error"]) {
              modificarErrorState({
                Error: false,
                Mensaje: "Todo bien, todo correcto",
              });
            }
            actualizarSessionStorage(
              "Cliente",
              JSON.stringify({
                ...clienteState,
                Sesion: true,
              })
            );
            modificarClienteState({
              ...clienteState,
              Sesion: true,
            });
            callback(true);
          } else {
            if (!errorState["Error"]) {
              modificarErrorState({
                Error: true,
                Mensaje: "Error al recuperar datos del servidor",
              });
            }
            callback(false);
          }
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
        })
        .catch(function (err) {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (!errorState["Error"]) {
            modificarErrorState({
              Error: true,
              Mensaje: "Error al recuperar datos del servidor",
            });
          }
        });
    });
  };

  //Verifica si el usuario ya esta registrado, si no se crea.
  const registrar = async () => {
    if (revisionDatosUsuarios()) {
      await ConsultarUsuario(
        clienteState["Documento"],
        async function (usuario) {
          if (usuario["Existe"]) {
            modificarErrorState({
              Error: true,
              Mensaje: "Este usuario ya esta registrado",
            });
          } else {
            await crearUsuario(async function (respuesta) {
              if (respuesta) {
                window.location.href = "/";
              }
            });
          }
        }
      );
    } else {
      modificarErrorState({
        Error: true,
        Mensaje: "Todos los campos son obligatorios",
      });
    }
  };

  //Obtiene el codigo JWT mediante las credenciales a la api rest.
  const getJWT = async (callback) => {
    await axios
      .post(apiUrlState + "/auth/get-token/", {
        username: "CroattaWeb",
        password: "Since2013",
      })
      .then((response) => {
        if (errorState["Error"]) {
          modificarErrorState({
            Error: false,
            Mensaje: "Todo bien, todo correcto",
          });
        }
        callback(response.data);
      })
      .catch(function (err) {
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        }
      });
  };

  //Consulta a un usuario en la Bd segun su numero de documento.
  const ConsultarUsuario = async (Identificacion, callback) => {
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Investigando tus antecedentes...",
      });
    }
    await getJWT(async function (token) {
      await axios
        .post(
          apiUrlState + "/Usuario/ConsultaUsuarioReact",
          {
            Id: Identificacion,
          },
          {
            headers: {
              authorization: "Bearer " + token["token"],
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            modificarSpinnerState({
              Desplegar: false,
              Texto: spinnerState["texto"],
            });
            if (errorState["Error"]) {
              modificarErrorState({
                Error: false,
                Mensaje: "Todo bien, todo correcto",
              });
            }
            callback(response.data);
          } else {
            modificarSpinnerState({
              Desplegar: false,
              Texto: spinnerState["texto"],
            });
            if (!errorState["Error"]) {
              modificarErrorState({
                Error: true,
                Mensaje: "Error al consultar usuario",
              });
            }
          }
        })
        .catch(function (err) {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (!errorState["Error"]) {
            modificarErrorState({
              Error: true,
              Mensaje: "Error al recuperar datos del servidor",
            });
          }
        });
    });
  };

  //Establece el campo de modo registro como false en el clienteState, tambien actualiza la localStorage.
  const cambioInicioSesion = () => {
    actualizarSessionStorage(
      "Cliente",
      JSON.stringify({
        ...clienteState,
        Registro: false,
      })
    );
    modificarClienteState({
      ...clienteState,
      Registro: false,
    });
  };

  //Establece el campo de modo registro como true en el clienteState, tambien actualiza la localStorage.
  const cambioRegistro = () => {
    actualizarSessionStorage(
      "Cliente",
      JSON.stringify({
        ...clienteState,
        Registro: true,
      })
    );
    modificarClienteState({
      ...clienteState,
      Registro: true,
    });
  };

  //Funcion que se ejecuta cada vez que se inicia este componente.
  //Pregunta si la sesion ya esta iniciada y si es asi redirecciona al /Mercado, tambien actualizando el localStorage.
  useEffect(() => {
    if (clienteState["Sesion"]) window.location.href = "/Mercado";
    var header = JSON.parse(JSON.stringify(headerState));
    if (header["displayBuscar"] !== "none") {
      header["displayBuscar"] = "none";
      header["displayFlecha"] = "none";
      modificarHeaderState(header);
    }
  });

  return (
    <div>
      {clienteState["Registro"] ? (
        <div className="CampoRegistro">
          <div>
            <p
              className="text-center FuenteBebas"
              style={{ fontSize: "50px", marginBottom: "20px" }}
            >
              Registro
            </p>
          </div>
          <Registro
            headerState={headerState}
            modificarHeaderState={modificarHeaderState}
            pedidoState={pedidoState}
            modificarPedidoState={modificarPedidoState}
            clienteState={clienteState}
            modificarClienteState={modificarClienteState}
            errorState={errorState}
            modificarErrorState={modificarErrorState}
            spinnerState={spinnerState}
            modificarSpinnerState={modificarSpinnerState}
            apiUrlState={apiUrlState}
            modificarApiUrlState={modificarApiUrlState}
            recursosUrlState={recursosUrlState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            actualizarSessionStorage={actualizarSessionStorage}
          />
          <div style={{ width: "100%" }}>
            <p
              className="text-center FuenteBebas volverInicio"
              onClick={() => cambioInicioSesion()}
              style={{ cursor: "pointer" }}
            >
              {"< Atrás"}
            </p>
          </div>
          <div>
            <p
              className="botonRegistro text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => registrar()}
            >
              Registrarme
            </p>
          </div>
        </div>
      ) : (
        <div className="IniciarSesion">
          <div className="formulariosCampoSesion">
            <div className="formularioSesion">
              <form onSubmit={(e) => onSubmit(e)} className="form-info">
                <div>
                  <p
                    className="text-center FuenteBebas"
                    style={{ fontSize: "50px" }}
                  >
                    Inicia Sesión
                  </p>
                </div>
                <div className="formularioIndividual">
                  <input
                    onChange={(e) => HandleChange(e)}
                    value={email}
                    className="text-center"
                    type="text"
                    placeholder="Ingrese su correo electrónico"
                    name="email"
                    id="usuario_input"
                  ></input>
                </div>
                <div className="formularioIndividual">
                  <input
                    onChange={(e) => HandleChange(e)}
                    className="text-center"
                    value={password}
                    type="password"
                    placeholder="Ingrese su contraseña"
                    name="password"
                    id="contraseña_input"
                  ></input>
                </div>
                
                  <button
                    className="botonIngresar text-center FuenteBebas"
                    style={{ cursor: "pointer" }}
                    
                  >
                    {loading ? "Cargando..." : "Iniciar Sesión"}
                  </button>
                
              </form>
            </div>
          </div>
          <div>
            <p
              className="text-center FuenteHelvetica registroLink"
              style={{ cursor: "pointer", fontSize: "15px" }}
              onClick={() => cambioRegistro()}
            >
              ¿Nuevo? Regístrate ahora.
            </p>
          </div>
          {/*<Link to="/">
            <div>
              <p
                className="FuenteBebas volverIndex"
                style={{ cursor: "pointer" }}
              >
                {"< Volver"}
              </p>
            </div>
      </Link>*/}
        </div>
      )}
    </div>
  );
};

export default InicioSesion;
