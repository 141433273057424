import React from "react";
import Registro from "../Usuario/Registro";
import axios from "axios";
import { useGlobalContext } from "../../providers/GlobalStatesProviders";

const RegistroTercero = ({
  headerState,
  modificarHeaderState,
  pedidoState,
  modificarPedidoState,
  clienteState,
  modificarClienteState,
  errorState,
  modificarErrorState,
  spinnerState,
  modificarSpinnerState,
  apiUrlState,
  modificarApiUrlState,
  recursosUrlState,
  modificarRecursosUrlState,
  actualizarSessionStorage,
})=> {
  // const { clienteState } = useGlobalContext();

  //Revisa que ningun datos del state de cliente este vacio.
  const revisionDatosUsuarios = () => {
  for (const dato in clienteState) {
      if (clienteState[dato] === "") return [false, dato];
    }
    return [true, ""];
  };

  //Segun los datos del state de cliente realiza una actualizacion de este en Contapyme.
  const actualizarDatosUsuario = async (callback) => {
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Actualizando tu datos...",
      });
    }
    await axios
      .post(apiUrlState + "/Api/Usuario/ActualizarUsuario", {
        NuevosDatosUsuario: [
          {
            NumeroDocumento: clienteState["NumeroDocumento"],
            TipoDocumento: clienteState["TipoDocumento"],
            Correo: clienteState["Correo"],
            Nombres: clienteState["Nombres"],
            Apellidos: clienteState["Apellidos"],
            FechaNacimiento: clienteState["FechaNacimiento"],
            Celular: clienteState["Celular"],
            Genero: clienteState["Genero"],
            Pais: clienteState["Pais"],
            Departamento: clienteState["Departamento"],
            Ciudad: clienteState["Ciudad"],
            Direccion: clienteState["Direccion"],
            Barrio: clienteState["Barrio"],
            Universidad: clienteState["Universidad"],
            TipoTercero: "Concursante",
          },
        ],
      })
      .then((response) => {
        if (!response.data.error) {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (errorState["Error"]) {
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          }
          callback(true);
        } else {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (!errorState["Error"]) {
            modificarErrorState({
              Error: true,
              Mensaje: response.data.message,
            });
          }
          callback(false);
        }
      })
      .catch(function (err) {
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        if (!errorState["Error"]) {
          modificarErrorState({ Error: true, Mensaje: "Revisa Tus Datos." });
        }
      });
  };

  const registrarParticipante = async ()=> {
    let revision = await revisionDatosUsuarios();
    if(revision[0]) {
      await actualizarDatosUsuario(async function (respuesta) {
        console.log(errorState["Error"])
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: "Registro exitoso",
          });
        }
        console.log(errorState["Error"])

      })
    } else {
      if (!errorState["Error"]) {
        modificarErrorState({
          Error: true,
          Mensaje: "Datos incompletos...",
          faltante: revision[1],
        });
      }
    }
  }

  return (
  <div>
    <h1 style={{marginTop: "3%", marginBottom:"3rem", textAlign:"center", fontWeight: "bold"}} >Concurso nacional de diseño</h1>
    <Registro 
      headerState={headerState}
      modificarHeaderState={modificarHeaderState}
      pedidoState={pedidoState}
      modificarPedidoState={modificarPedidoState}
      clienteState={clienteState}
      modificarClienteState={modificarClienteState}
      errorState={errorState}
      modificarErrorState={modificarErrorState}
      spinnerState={spinnerState}
      modificarSpinnerState={modificarSpinnerState}
      apiUrlState={apiUrlState}
      modificarApiUrlState={modificarApiUrlState}
      recursosUrlState={recursosUrlState}
      modificarRecursosUrlState={modificarRecursosUrlState}
      actualizarSessionStorage={actualizarSessionStorage}
    />
    
    <div className="">
        <p
          className="botonPaso2 text-center FuenteBebas"
          style={{ cursor: "pointer" }}
          onClick={() => registrarParticipante()}
        >
          Registrar
        </p>
    </div>
  </div>
  )
}

export default RegistroTercero;