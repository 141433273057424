import React, { useEffect, useState } from "react";
import "../static/Indicadores.css";
import { FaFileUpload, FaServer, FaCloudUploadAlt  } from 'react-icons/fa';

const Imagenes = ({ apiUrlState }) => {
  const [archivos, setArchivos] = useState(null);

  const subirArchivos = (file) => {
    setArchivos(file);
  };

  const insertarArchivos = async () => {
    const formData = new FormData();
    for (let index = 0; index < archivos.length; index++) {
      formData.append("file", archivos[index]);
    }
    let carga = await fetch(apiUrlState + "/Api/imagenes/upload", {
      method: "POST",
      body: formData,
    });

    console.log(carga.status);

    if (carga.status == 202) {
      document.getElementById("preview").innerHTML = "";
      document.getElementById("carga").innerHTML =
        "por Favor de click en Carga hosting";
      console.log(carga, "**********");
    }
  };

  // const insertarHost = async () => {
  //   let carga = await fetch(apiUrlState + "/Api/imagenes/upload2", {
  //     method: "GET",
  //   });

  const insertarHost = async () => {
    let carga = await fetch(apiUrlState + "/Api/Upload/upload-images", {
      method: "POST",
      body: {},
    });

  

    console.log(carga.status);

    if (carga.status == 201) {
      document.getElementById("preview").innerHTML = "";
      document.getElementById("carga").innerHTML =
        "Proceso de carga iniciado, se notifica por correo cuando el proceso concluya";
      console.log(carga, "**********");
    }
    console.log(apiUrlState + "/Api/imagenes/upload2");
    
  };

  const listarArchivos = (files) => {
    console.log(files);
    console.log(typeof files);
    let ul = document.getElementById("preview");

    if (files.length > 0) {
      for (let index = 0; index < files.length; index++) {
        console.log(files[index].name);
        let li = document.createElement("li");
        li.appendChild(document.createTextNode(files[index].name));
        ul.appendChild(li);
      }
    } else {
      document.getElementById("preview").innerHTML = "";
    }
  };

  return (
    <div className="container" style={{ marginTop: "7rem" }}>
      <h1>CARGA DE FOTOS</h1>
      <label>
        {" "}
        recuerda que los nombres de las fotos debe tener la forma Ref
        000AAAA.webp (actualmente solo cuenta con subida de foto para pagina
        web)
      </label>
      <div className="table-responsive">
        <table className="table">
          <tbody style={{ textAlign: "center" }}>
            <tr>
              <td style={{display: "flex", flexDirection: "column"}}>
                <label  style={{ textAlign: "center", fontWeight: "bold" }}>Paso 1: Cargar Imagenes</label>
                <label className="btn btn-dark">
                  <input
                    id="files"
                    type="file"
                    multiple
                    accept="image/webp"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      subirArchivos(e.target.files);
                      listarArchivos(e.target.files);
                    }}
                  />
                  <FaFileUpload size={35} className="file-icon" />
                </label>
              </td>
              <td style={{display: "flex", flexDirection: "column"}}>
                <label  style={{ textAlign: "center", fontWeight: "bold" }}>Paso 2: Subir Imagenes al servidor</label>
                <button
                  type="button"
                  onClick={() => insertarArchivos()}
                  value="Subir archivos"
                  className="btn btn-dark"
                  
                ><FaServer size={35} className="file-icon" /></button>
              </td>
              <td style={{display: "flex", flexDirection: "column"}}>
                <label  style={{ textAlign: "center", fontWeight: "bold" }}>Paso 3: Subir Imagenes a Hostinger</label>
                <button
                  type="button"
                  onClick={() => insertarHost()}
                  value="Carga Hosting"
                  className="btn btn-dark"
                ><FaCloudUploadAlt size={35} className="file-icon" /></button>
              </td>
            </tr>
            <tr>
              <td colSpan="3">
              <label  style={{ textAlign: "center", fontWeight: "bold", fontSize: "30px" }}>Imagenes seleccionadas: </label>
                <ul
                  className="press"
                  style={{ textAlign: "center", fontWeight: "bold" }}
                  id="preview"
                ></ul>
                <label
                  className="press"
                  style={{ textAlign: "center", fontWeight: "bold" }}
                  id="carga"
                ></label>
              </td>
            </tr>
          </tbody>
        </table>
        {/* <UploadImage /> */}
        {/* <ImageUploader /> */}
        {/* <ImageUploader2 /> */}
       
      </div>
    </div>
  );
};

export default Imagenes;
