import React, { useEffect, useState } from "react";
import axios from "axios";
import "../static/Indicadores.css";
import { Link, useParams, useHistory } from "react-router-dom";
import swal from "sweetalert";

const RotacionIndex = ({
  SetLandingHeader,
  apiUrlState,
  recursosUrlState,
  modificarSpinnerState,
}) => {
  const VolverAdmin = () => {
    window.location.href = "/Admin/Rotacion";
  };

  let { periodo } = useParams();
  const [LRotacion, GetListaRotacion] = useState([
    {
      _id: "61e844699370522d20653248",
      mes: "10",
      anio: "2021",
      Estado: "PRUEBA",
      Periodo: "PRUEBA 2021",
      Markeplace: "croatta",
    },
  ]);

  //Login validation
  const token = localStorage.getItem("token");
  const history = useHistory();
  useEffect(() => {
    if (token) {
      
    }else{
      history.push("/login")
    }
    //console.log(reports)
  },[token]);

  const ActulizarRotacion = async (mes, anio, periodo) => {
    modificarSpinnerState({
      Desplegar: true,
      Texto: "actualizando rotacion",
    });
    await axios
      .post(apiUrlState + "/Api/Unidades/CrearRotacionUnidades", {
        mes: mes,
        anio: anio,
        periodo: periodo,
      })
      .then((response) => {
        modificarSpinnerState({
          Desplegar: false,
          Texto: "actualizando rotacion",
        });
        VolverAdmin();
      })
      .catch(function (err) {
        console.log("Error");
      });
  };

  const Rotacion = async () => {
    await axios
      .post(apiUrlState + "/Api/Unidades/RotacionUnidades/Periodo", {
        Periodo: periodo,
      })
      .then((response) => {
        GetListaRotacion(response.data.data);
      })
      .catch(function (err) {
        console.log("Error");
      });
  };

  useEffect(() => {
    SetLandingHeader({ Estado: true });
    Rotacion();
  }, []);

  return (
    <div>
      <h1
        style={{ marginTop: "150px", textAlign: "center", fontWeight: "bold" }}
      >
        ROTACIÓN DE UNIDADES <br /> {LRotacion[0].Periodo}
      </h1>

      <div className="container">
        <div className="table-responsive">
          <table className="table">
            <thead style={{ marginTop: "50px", textAlign: "center" }}>
              <tr>
                <th
                  scope="col"
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                >
                  Markeplace
                </th>
                <th
                  scope="col"
                  colSpan="2"
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                >
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody>
              {LRotacion.map((mes) =>
                mes.Periodo !== "PRUEBA 2021" ? (
                  <tr style={{ textAlign: "center" }}>
                    <th style={{ textAlign: "center" }}>{mes.Markeplace}</th>
                    <th>
                      <Link to={"/Admin/Rotacion/" + mes._id}>
                        <button
                          type="button"
                          className="btn btn-dark"
                          style={{ fontSize: "10px", width: "120px" }}
                        >
                          Ver Mas
                        </button>
                      </Link>
                    </th>
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RotacionIndex;
