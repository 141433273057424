import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import "../static/Indicadores.css";
import swal from "sweetalert";

const Indicadores = ({ SetLandingHeader, apiUrlState, recursosUrlState }) => {
  const [ListMeta, GetListMeta] = useState([
    {
      _id: "78858787",
      Periodo: "01 January 2021 - 31 January 2021",
      Ingresos: "$ 5.002.567",
      Meta: "$ 10.000.000",
      Cumplida: "No",
      Opciobes: "*",
    },
  ]);

  const token = localStorage.getItem("token");
  const history = useHistory();
  useEffect(() => {
    if (token) {
      
    }else{
      history.push("/login")
    }
    //console.log(reports)
  },[token]);


  const VolverAdmin = () => {
    window.location.href = "/Admin/Index/MenuPrincipal";
  };

  const formatNumber = (amount) => {
    if (ListMeta.IngresosBancarios !== "$ --") {
      var temp = amount + "";
      var i = temp.length - 3;
      while (i > 0) {
        temp = temp.substring(0, i) + "." + temp.substring(i);
        i -= 3;
      }
      return " " + temp;
    }
  };

  const Cumplida = (amount) => {
    let condicion = "";
    if (amount) {
      return (condicion = "Si");
    } else {
      return (condicion = "No");
    }
  };
  const obtenerMetas = async () => {
    await axios
      .post(
        apiUrlState + "/Api/Indicadores/GetMetas",
        {
          data: {},
        },
        {}
      )
      .then((response) => {
        GetListMeta(response.data);
      })
      .catch(function (err) {
        console.log("Error");
      });
  };
  const DeleterMeta = async (id) => {
    swal({
      title: "Esta seguro de eliminar esta Meta ?",
      text: "Al eliminarla no se podran recuperar los datos",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .post(
            apiUrlState + "/Api/Indicadores/DeleteMeta",
            {
              data: {
                _id: id,
              },
            },
            {}
          )
          .then((response) => {
            window.location.href = "/Admin/Indicadores";
          })
          .catch(function (err) {
            console.log("Error");
          });

        swal("Poof! Meta Eliminada", {
          icon: "success",
        });
      } else {
        swal("La Meta no se eliminó");
      }
    });
  };

  useEffect(() => {
    SetLandingHeader({ Estado: true });
    obtenerMetas();
  }, []);
  return (
    <div>
      <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
        Listado de Metas
      </h1>
      <div className="row" style={{ justifyContent: "center" }}>
        <Link to="/Admin/CrudIndicadores">
          <p
            className="botonLimpiarFiltros text-center FuenteBebas"
            style={{ cursor: "pointer", width: "150px" }}
          >
            Nueva meta
          </p>
        </Link>
      </div>
      <div className="container">
        <div className="table-responsive">
          <table className="table">
            <thead style={{ marginTop: "50px", textAlign: "center" }}>
              <tr>
                <th
                  scope="col"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                  Periodo
                </th>
                <th
                  scope="col"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                  Ingresos
                </th>
                <th
                  scope="col"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                  Meta
                </th>
                <th
                  scope="col"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                  Cumplida
                </th>
                <th
                  colSpan="2"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {ListMeta.map((Meta) => (
                <tr key={Meta._id}>
                  <th scope="row">{Meta.Periodo}</th>
                  <td>$ {formatNumber(Meta.Ingresos)}</td>
                  <td>$ {formatNumber(Meta.Meta)}</td>
                  <td>{Cumplida(Meta.MetaCumplida)}</td>
                  <td>
                    <Link to={"/Admin/DetalleMeta/" + Meta._id}>
                      <button
                        type="button"
                        className="btn btn-dark"
                        style={{ fontSize: "10px", width: "120px" }}
                      >
                        Ver Mas
                      </button>
                    </Link>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-dark"
                      style={{ fontSize: "10px" }}
                      onClick={() => DeleterMeta(Meta._id)}
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Indicadores;
