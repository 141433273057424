import React,  { useEffect, useState } from 'react'
import { Link, useHistory } from "react-router-dom";
import NavBar from './NavBar';
import SidebarData from './Sidebar';

const Dashboard = ({setMenuView ,setAdminHeaderState}) => {


  const token = localStorage.getItem("token");
  const history = useHistory();
  useEffect(() => {
    if (token || setMenuView) {
      setAdminHeaderState({
        second: 'SecondHeaderamd', 
        principal: 'centerHeaderamd'})
    }else{
      history.push("/login")
    }
    //console.log(reports)
  },[token]);

  
  return (
    <div className='top'>
    <NavBar setView={setMenuView}/>
    <div className='flex'>
        
        <div className='content FuenteHelvetica'>

        </div>
    </div>
    
    </div>
  )
}

export default Dashboard;