import { useState, useContext, createContext } from 'react'


/** Se crea un contexto para usar en NewIndex y Mercado
 * para manejar las categorias
 */
const CategoriasContext = createContext();

export const useCategContext = () => {
  return useContext(CategoriasContext);
}

export const CategoriasProvider = ({children}) => {

  const [categorias, setCategorias] = useState([
    /** Categoria Ejemplo */
      //  [
      //     "NOMBRE CATEGORIA",
      //     "URL_IMAGEN_CATEGORIA",
      //     ["LISTA_CATEGORIAS"],
      //     ["LISTA_SUBCATEGORIAS"],
      //     ["LISTA_MARCAS"],
      //     ["GENERO"],
      //     "CONSULTA_PRODUCTOS_NUEVOS",
      //     "Hombre ó Mujer",
      //     "IDENTIFICACION_CATEGORIA",
      //     "CONSULTA_PRODUCTOS_DESCUENTO"
      //  ],
      // [
      //   "NEW",
      //   "https://hosting.croatta.com/img/Index/web/carousel/fondo2Movil.png",  
      //   [],
      //   [],
      //   [],
      //   [],
      //   true,
      //   "New",
      //   "Nuevo",
      //   false
      // ],
      [
        "GORRAS",
        "https://hosting.croatta.com/img/Index/web/home/CATEGORIA%20GORRAS.webp",
        [{ Categoria: "607ed63e521b1c0910a581ac" }],
        [], // subcategoria
        [], // marca
        [ { Genero: "607ed653521b1c0910a581bb" } ], // genero
        false,
        "Unisex",
        "Gorra",
        false,
      ],
      [
        "CAMISETAS ESTAMPADAS HOMBRE",
        "https://hosting.croatta.com/img/Index/web/home/CATEGORIA%20ESTAMPADAS%20HOMBRE.webp",
        [{ Categoria: "607ed63e521b1c0910a581aa" }],
        [ { Subcategoria: "607ed65a521b1c0910a581bf" },
        ],
        [],
        [{ Genero: "607ed653521b1c0910a581b8" }],
        false,
        "Hombre",
        "Estampada",
        false,
      ],
      [
        "CAMISETAS BÁSICAS HOMBRE",
        "https://hosting.croatta.com/img/Index/web/home/CATEGORIA%20BASICAS.webp",
        [{ Categoria: "607ed63e521b1c0910a581aa" }],
        [
          { Subcategoria: "607ed65a521b1c0910a581bd" },
          { Subcategoria: "65a53552e8989878c228d032" },
        ],
        [],
        [{ Genero: "607ed653521b1c0910a581b8" }],
        false,
        "Hombre",
        "Basica",
        false,
      ],
      [
        "POLOS",
        "https://hosting.croatta.com/img/Index/web/home/CATEGORIA%20POLOS.webp",
        [{ Categoria: "607ed63f521b1c0910a581b1" }],
        [], // subcategoria
        [], // Marca
        [ { Genero: "607ed653521b1c0910a581b8" } ],
        false,
        "Hombre",
        "Polo",
        false,
      ],
      [
        "PANTALONES",
        "https://hosting.croatta.com/img/Index/web/home/CATEGORIA%20PANTALONES.webp",
        [{ Categoria: "66ab94a0384bbb45fbe9ee0c" }],
        [], // subcategoria
        [], // Marca
        [ { Genero: "607ed653521b1c0910a581b8" } ],
        false,
        "Hombre",
        "Pantalon",
        false,
      ],
      // [
      //   "CAMISETAS ESTAMPADAS MUJER",
      //   "https://hosting.croatta.com/img/Index/web/home/CATEGORIA%20ESTAMPADAS%20MUJER.webp",
      //   [{ Categoria: "607ed63e521b1c0910a581aa" }],
      //   [{ Subcategoria: "607ed65a521b1c0910a581bf" }],
      //   [],
      //   [{ Genero: "607ed653521b1c0910a581b9" }],
      //   false,
      //   "Mujer",
      //   "Camiseta",
      //   false,
      // ],
  
      [
        "BLUSAS BÁSICAS DK",
        "https://hosting.croatta.com/img/Index/web/home/BLUSAS%20BASICAS.webp",
        [
          { Categoria: "651aec4251072238a0969749" }, // Blusa
          // { Categoria: "607ed63e521b1c0910a581aa" }, // Camiseta
          // { Categoria: "655bad5a1cdc932b9e974a01" }, // Body
          // { Categoria: "6583763e8dfd4f911a2d0388" }, // Seamless - Tubular
        ],
        [
          { Subcategoria: "607ed65a521b1c0910a581bd" }, // basica
          // { Subcategoria: "65a53552e8989878c228d032" }, // general
        ],
        [], // { Marca: "64f8df51ab8f6199b7154137" }
        [ { Genero: "607ed653521b1c0910a581b9" } ],
        false,
        "Mujer",
        "Blusa",
        false,
      ],

      [
        "CAMISETAS BÁSICAS CROATTA",
        "https://hosting.croatta.com/img/Index/web/home/CAMISETAS%20BASICAS.webp",
        [
          // { Categoria: "651aec4251072238a0969749" }, // Blusa
          { Categoria: "607ed63e521b1c0910a581aa" }, // Camiseta
          // { Categoria: "655bad5a1cdc932b9e974a01" }, // Body
          // { Categoria: "6583763e8dfd4f911a2d0388" }, // Seamless - Tubular
        ],
        [
          { Subcategoria: "607ed65a521b1c0910a581bd" }, // basica
          // { Subcategoria: "65a53552e8989878c228d032" }, // general
        ],
        [], // { Marca: "64f8df51ab8f6199b7154137" }
        [ { Genero: "607ed653521b1c0910a581b9" } ],
        false,
        "Mujer",
        "CamisetaB",
        false,
      ],
      // [
      //   "PANTALONETAS",
      //   "https://hosting.croatta.com/img/Index/web/home/CATEGORIA%20PANTALONETAS.webp",
      //   [{ Categoria: "607ed63e521b1c0910a581b0" }],
      //   [], // subcategoria
      //   [], // Marca
      //   [ { Genero: "607ed653521b1c0910a581b8" } ],
      //   false,
      //   "Hombre",
      //   "Pantaloneta",
      //   false,
      // ],  
      // [
      //   "CAMISETAS ESTAMPADAS DK",
      //   "https://hosting.croatta.com/img/Index/web/home/BasicasM.png",
      //   { Categoria: "607ed63e521b1c0910a581aa" },
      //   { Subcategoria: "607ed65a521b1c0910a581bf" },
      //   [{ Marca: "64f8df51ab8f6199b7154137" }],
      //   { Genero: "607ed653521b1c0910a581b9" },
      //   false,
      //   "Mujer",
      //   "CamisetaEsDk",
      //   false,
      // ],
  
      // [
      //   "VALTIMOR",
      //   "https://hosting.croatta.com/img/Index/web/home/Camisetas%20hombre.png",
      //   { Categoria: "607ed63e521b1c0910a581aa" },
      //   null,
      //   [
      //     { Marca: "607ed64c521b1c0910a581b6" }, //fishbone
      //     { Marca: "607ed64c521b1c0910a581b5" }, //staff
      //     { Marca: "607ed64c521b1c0910a581b4" }, //urbi
      //     { Marca: "607ed64c521b1c0910a581b7" }, //valtimor
      //   ],
      //   { Genero: "607ed653521b1c0910a581b8" },
      //   false,
      // ],

      // [
      //   "VESTIDOS",
      //   "https://hosting.croatta.com/img/Index/web/home/VESTIDOS.webp",
      //   [ { Categoria: "65578d4701bf5211b63d14bc" } ],
      //   [],
      //   [ { Marca: "64f8df51ab8f6199b7154137" } ],
      //   [ { Genero: "607ed653521b1c0910a581b9" } ],
      //   false,
      //   "Mujer",
      //   "Vestido",
      //   false,
      // ],
  
      // [
      //   "LYCRAS",
      //   "https://hosting.croatta.com/img/Index/web/home/LYCRAS.webp",
      //   [{ Categoria: "6525a7906b44bb8bf3323210" }],
      //   [],
      //   [],
      //   [ { Genero: "607ed653521b1c0910a581b9" } ],
      //   false,
      //   "Mujer",
      //   "Lycra",
      //   false,
      // ],
      // [
      //   "HOODIES HOMBRE",
      //   "https://hosting.croatta.com/img/Index/web/home/Hoodies%20Hombre.png",
      //   { Categoria: "607ed63e521b1c0910a581ad" },
      //   null,
      //   null,
      //   { Genero: "607ed653521b1c0910a581b8" },
      //   false,
      //   "Hombre",
      //   "Hoodie",
      // ],
      // [
      //   "HOODIES MUJER",
      //   "https://hosting.croatta.com/img/Index/web/home/Hoodies%20Mujer.png",
      //   { Categoria: "607ed63e521b1c0910a581ad" },
      //   null,
      //   null,
      //   { Genero: "607ed653521b1c0910a581b9" },
      //   false,
      //   "Mujer",
      //   "Hoodie",
      // ],
      // [
      //   "JOGGER HOMBRE",
      //   "https://hosting.croatta.com/img/Index/web/home/Jogger%20hombre.png",
      //   { Categoria: "607ed63e521b1c0910a581af" },
      //   null,
      //   null,
      //   { Genero: "607ed653521b1c0910a581b8" },
      //   false,
      //   "Hombre",
      //  "Jogger",
      // ],
      // [
      //   "JOGGER MUJER",
      //   "https://hosting.croatta.com/img/Index/web/home/Jogger%20mujer.png",
      //   { Categoria: "607ed63e521b1c0910a581af" },
      //   null,
      //   null,
      //   { Genero: "607ed653521b1c0910a581b9" },
      //   false,
      //   false,
      //   "Mujer",
      //   "Jogger",
      // ],
      // [
      //   "NIÑOS",
      //   "https://hosting.croatta.com/img/Index/web/carousel/fondo3Movil.png",
      //   null,
      //   null,
      //   null,
      //   { Genero: "607ed653521b1c0910a581ba" },
      //   false,
      //  "Niño",
      //  "Camiseta",
      // ],
      // [
      //   "REBAJAS",
      //   "https://hosting.croatta.com/img/Index/web/home/Descuentos.webp",
      //   [],
      //   [],
      //   [],
      //   [],
      //   false,
      //   "Todo",
      //   "Descuento",
      //   true,
      // ],
  ]);

  return (
    <CategoriasContext.Provider value={categorias}>
      {children}
    </CategoriasContext.Provider>
  )

}