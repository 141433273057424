import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import "../static/Indicadores.css";

const CrudIndicadores = ({ SetLandingHeader, apiUrlState }) => {
  const [Meta, GetMeta] = useState({
    FechaI: " ",
    FechaF: "",
    Meta: "",
    Ingresos: "",
  });

  const onFileChanges = (e, tipo) => {
    if (tipo === "Ingresos") {
      GetMeta({
        ...Meta,
        FechaI: e.target.value,
      });
    }
    if (tipo === "Meta") {
      GetMeta({
        ...Meta,
        Meta: e.target.value,
      });
    }
    if (tipo === "Ingresos") {
      GetMeta({
        ...Meta,
        Ingresos: e.target.value,
      });
    }
    if (tipo === "FechaF") {
      GetMeta({
        ...Meta,
        FechaF: e.target.value,
      });
    }
    if (tipo === "FechaI") {
      GetMeta({
        ...Meta,
        FechaI: e.target.value,
      });
    }
  };

  const CrearMeta = async () => {
    await axios
      .post(
        apiUrlState + "/Api/Indicadores/CreateMeta",
        {
          data: {
            FechaInicial: Meta.FechaI,
            FechaFinal: Meta.FechaF,
            Meta: Meta.Meta,
            Ingresos: Meta.Ingresos,
          },
        },
        {}
      )
      .then((response) => {
        window.location.href = "/Admin/Indicadores";
      })
      .catch(function (err) {
        console.log("Error");
      });
  };

  const token = localStorage.getItem("token");
  const history = useHistory();
  useEffect(() => {
    if (token) {
      
    }else{
      history.push("/login")
    }
    //console.log(reports)
  },[token]);

  useEffect(() => {
    SetLandingHeader({ Estado: true });
  }, []);
  return (
    <div>
      <h1
        style={{ marginTop: "150px", textAlign: "center", fontWeight: "bold" }}
      >
        Registrar Meta
      </h1>
      <div className="container">
        <form style={{ marginTop: "50px" }}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="fechaInicial" style={{ fontSize: "17px" }}>
                (*) Fecha Inicial
              </label>
              <input
                onChange={(e) => onFileChanges(e, "FechaI")}
                type="date"
                style={{ fontSize: "15px" }}
                placeholder="Ingrese su fecha de nacimiento"
                name="FechaNacimiento"
                id="fechaNacimiento_input"
                min="1910-01-01"
                required
              ></input>
            </div>
            <div className="form-group col-md-6 ">
              <label htmlFor="celular">(*) Meta</label>
              <input
                onChange={(e) => onFileChanges(e, "Meta")}
                type="text"
                placeholder="Ingrese una meta"
                name="Celular"
                id="celular_input"
                required
              ></input>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="fecha" style={{ fontSize: "17px" }}>
                (*) Fecha Final
              </label>
              <input
                onChange={(e) => onFileChanges(e, "FechaF")}
                type="date"
                style={{ fontSize: "15px" }}
                placeholder="Ingrese su fecha de nacimiento"
                name="FechaNacimiento"
                id="fechaNacimiento_input"
                min="1910-01-01"
                required
              ></input>
            </div>
            {/*<div className="form-group col-md-6">
                    <label htmlFor="celular" disabled>(*) Ingresos</label>
                    <input onChange={ (e) => onFileChanges(e, "Ingresos")} type="text" placeholder="Ingrese su numero de celular" name="Celular"  id="celular_input" required></input>
                </div>*/}
          </div>
          <div className="row">
            <div className="col-6" style={{ textAlign: "center" }}>
              <div>
                <Link to="/Admin/Indicadores">
                  <p
                    className="botonLimpiarFiltros text-center FuenteBebas"
                    style={{ cursor: "pointer" }}
                  >
                    Cancelar
                  </p>
                </Link>
              </div>
            </div>

            <div className="col-6" style={{ textAlign: "center" }}>
              <div>
                <p
                  className="botonLimpiarFiltros text-center FuenteBebas"
                  style={{ cursor: "pointer" }}
                  onClick={() => CrearMeta()}
                >
                  Guardar
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CrudIndicadores;
