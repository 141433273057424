//Zona de importacion de componentes externos.
import React, { useEffect, useState } from "react";
import swal from "sweetalert";

//Zona de importacion de componentes internos.
import axios from "axios";
import { usePreventaContext } from "../../providers/PreventaProviders";

/** Importacion de servicios */
import apiFacebook from "../../services/apiFacebook";

//Zona de importacion de Css.
import "./static/css/GrupoTallas/Tallas.css";
import { useGlobalContext } from "../../providers/GlobalStatesProviders";

const GrupoTallas = ({
  ViewDetalle,
  carrito,
  modificarCarritoState,
  producto,
  errorState,
  modificarErrorState,
  apiUrlState,
  modificarApiUrlState,
  recursosUrlState,
  modificarRecursosUrlState,
  headerState,
  modificarHeaderState,
  spinnerState,
  modificarSpinnerState,
  actualizarSessionStorage,
  tallasState,
  modificarTallasState,
  mostar,
}) => {
  const { vendedor } = usePreventaContext();
  const { procomponente, componentes, actualizarComponente } = useGlobalContext();

  const [tallas, setTallas] = useState([]);
  const [tallaSeleccionada, setTallaSelecionada] = useState([]);
  const [cantidad, setCantidad] = useState(0);
  const [adicion, setAdicion] = useState(0);

  /** Envia el evento de agregar al carrito pagina web a facebook */
  const enviarEventoFacebook = async (producto) => {
    const userIP = await apiFacebook.get();
    const item_price =  headerState["Cliente"] == "Mayoristas"
      ? producto.PrecioMayorista
      : producto.PrecioMinorista;
    apiFacebook.post(`${apiUrlState}/Api/Facebook/SendEvent`, {
      event_id: `cart-${Date.now()}`,
      event_name: "AddToCart",
      event_source_url: window.location.href,
      client_user_agent: navigator.userAgent,
      client_ip_address: userIP || "0.0.0.0",
      custom_data: {
        currency: "COP",
        contents: [
          {
            id: producto.id,
            item_price,
          }
        ]
      }
    });

    /** Activar evento mediante pixel -> Pendiente */
    
  }

  /** Control del input de cantidades para que no se puedan ingresar mas de dos digitos */
  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,2}$/.test(value)) { // Limitar a 2 dígitos
      setAdicion(value);
    }
  };

  //Se realiza la peticion para la verificacion de un producto solicitando la cantidad de este en bodega.
  const ConsultarBodega = async (idProducto, codBodega) => {
    let res = -100;
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Agregando producto...",
      });
    }
    // if (idProducto.indexOf("GU") !== -1 || idProducto.indexOf("GFU") !== -1)
    // if (idProducto.slice(-1) === "U")
    //   idProducto = idProducto.slice(0, -1);


    let rutaVerificacion = "/Api/Contapyme/VerificarProductoContapyme/" + idProducto
    if (vendedor) {
      rutaVerificacion = "/Api/Contapyme/VerificarProductoContapyme/" + idProducto + `/${codBodega}`;
    }

    await axios
      .get(
        apiUrlState + rutaVerificacion,
        {},
        {}
      )
      .then((response) => {
        if (response.data.existe) {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (errorState["Error"])
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          res = response.data.cantidad;
          return response.data.cantidad;
        } else {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (!errorState["Error"])
            modificarErrorState({
              Error: true,
              Mensaje: "Talla no disponible para esta referencia.",
            });
          return -100;
        }
      })
      .catch(function (err) {
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: err.response.data.error_message,
          });
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
        }
      });
    return res;
  };

  //Retorna el precio ingresado sin signos de peso ni puntos.
  const arreglarPrecio = (precio) => {
    var separacion = precio;
    if (precio.indexOf("$") !== -1) separacion = precio.split(" ")[1];
    var separacion2 = separacion.split(".");
    return parseInt(separacion2[0] + separacion2[1]);
  };

  //Se realiza la validacion en bodega para aumentar en 1 la cantidad en carrito de este, si no existe lo crea.
  //Actualiza el state del carrito y tambien el sessionStorage.
  const adicionar = async (talla, cantidadActual) => {
    if (tallaSeleccionada.length !== 0) {
      const disponible = procomponente.has(producto.Referencia + talla)
        ? componentes.get(procomponente.get(producto.Referencia + talla))
        : cantidad - obtenerCantidadTallaProductoActual(producto.Referencia + talla);      
      if (disponible > 0) {
        let crear = true;
        for (var i = 0; i < carrito["Carrito"].length; i++) {
          if (producto.Referencia + talla === carrito["Carrito"][i]["id"]) {
            carrito["Carrito"][i]["Cantidad"] += 1;
            crear = false;
          }
        }
        if (crear) {
          const productoNuevo = {
            _id: producto._id,
            id: producto.Referencia + talla,
            Nombre: producto.Nombre,
            Codigo: producto.Referencia,
            Componente: procomponente.get(producto.Referencia + talla),
            Talla: talla,
            Bodega: producto.CodBodega,
            Categoria: producto.Categoria.Categoria,
            PrecioMinorista: arreglarPrecio(producto.PrecioMinorista),
            PrecioMayorista: arreglarPrecio(producto.PrecioMayorista),
            DescuentoMayorista: producto.DescuentoMayorista,
            DescuentoMinorista: producto.DescuentoMinorista,
            Cantidad: 1,
          };
          const carritoNew = [...carrito["Carrito"], productoNuevo];
          modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
          actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
          enviarEventoFacebook(productoNuevo)
        } else {
          const carritoNew = [...carrito["Carrito"]];
          modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
          actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
        }
        actualizarComponente(producto.Referencia + talla, - 1) // Aqui revisar
      }
    }
  };

  /** Agrega las cantidades del input al carrito validando existencias */
  const adicionarV = async (talla, cantidadActual, cantidadAdicion) => {
    let undAdicion = cantidadAdicion;
    if (tallaSeleccionada.length !== 0) {
      let cantidadReal = cantidad; //await ConsultarBodega(producto.Referencia + talla, producto.CodBodega);
      if (cantidadActual + parseInt(undAdicion) <= cantidadReal) {
        auxAdicionarV(talla, undAdicion)
        setAdicion(1);
      } else {
        undAdicion = cantidadReal - cantidadActual;
        if(undAdicion > 0) {
          swal({
            title: "Aviso",
            text: `La cantidad solicitada de la referencia ${producto.Referencia + talla} no está disponible.
              Se agregarán ${undAdicion} de ${cantidadAdicion} al carrito, desea continuar?`,
            icon: "info",
            buttons: {
              cancel: {
                text: "No",
                value: null,
                visible: true,
                closeModal: true,
              },
              confirm: {
                text: "Si",
                value: true,
                visible: true,
                closeModal: true
              }
            }
          }).then(response => {
            if (response) {
              auxAdicionarV(talla, undAdicion)
            }
            setAdicion(1);
          });
        } else {
          if(obtenerCantidadTallaProductoActual(tallaSeleccionada.label) == 0) {
            swal({
              title: "¡Importante!",
              text: `La referencia ${producto.Referencia + talla} no será agregada al carrito,
              es posible que esté agotada. Si se presenta la novedad con todas las referencias,
              solicite soporte.`,
              icon: "warning",
            });
          }
        }
      }
    }
  };

  const adicionarV2 = async (talla, cantidadActual, cantidadAdicion) => {
    let undAdicion = cantidadAdicion;
    if (tallaSeleccionada.length !== 0) {
      const disponible = procomponente.has(producto.Referencia + talla)
        ? componentes.get(procomponente.get(producto.Referencia + talla))
        : cantidad - obtenerCantidadTallaProductoActual(producto.Referencia + talla);
      
      if (parseInt(undAdicion) <= disponible) {
        auxAdicionarV(talla, undAdicion)
        setAdicion(1);
      } else {
        undAdicion = disponible;
        if(undAdicion > 0) {
          swal({
            title: "Aviso",
            text: `La cantidad solicitada de la referencia ${producto.Referencia + talla} no está disponible.
              Se agregarán ${undAdicion} de ${cantidadAdicion} al carrito, desea continuar?`,
            icon: "info",
            buttons: {
              cancel: {
                text: "No",
                value: null,
                visible: true,
                closeModal: true,
              },
              confirm: {
                text: "Si",
                value: true,
                visible: true,
                closeModal: true
              }
            }
          }).then(response => {
            if (response) {
              auxAdicionarV(talla, undAdicion)
            }
            setAdicion(1);
          });
        } else {
          // if(obtenerCantidadTallaProductoActual(tallaSeleccionada.label) == 0) {
            swal({
              title: "¡Importante!",
              text: `La referencia ${producto.Referencia + talla} no será agregada al carrito,
              es posible que esté agotada. Si se presenta la novedad con todas las referencias,
              solicite soporte.`,
              icon: "warning",
            });
          // }
        }
      }
    }
  };

  const auxAdicionarV = (talla, cantidadAdicion) => {
    let crear = true;
    for (var i = 0; i < carrito["Carrito"].length; i++) {
      if (producto.Referencia + talla === carrito["Carrito"][i]["id"]) {
        carrito["Carrito"][i]["Cantidad"] += parseInt(cantidadAdicion);
        crear = false;
      }
    }
    if (crear) {
      const productoNuevo = {
        _id: producto._id,
        id: producto.Referencia + talla,
        Nombre: producto.Nombre,
        Codigo: producto.Referencia,
        Componente: procomponente.get(producto.Referencia + talla),
        Talla: talla,
        Bodega: producto.CodBodega,
        Categoria: producto.Categoria.Categoria,
        PrecioMinorista: arreglarPrecio(producto.PrecioMinorista),
        PrecioMayorista: arreglarPrecio(producto.PrecioMayorista),
        DescuentoMayorista: producto.DescuentoMayorista,
        DescuentoMinorista: producto.DescuentoMinorista,
        Cantidad: parseInt(cantidadAdicion),
      };
      const carritoNew = [...carrito["Carrito"], productoNuevo];
      modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
      actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
    } else {
      const carritoNew = [...carrito["Carrito"]];
      modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
      actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
    }
    actualizarComponente(producto.Referencia + talla, - cantidadAdicion) // Aqui revisar

  }

  const obtenerCantidadTallaProductoActual = (talla) => {
    const productoFiltrado = carrito["Carrito"].filter(
      (prod) => prod["id"] === producto.Referencia + talla
    );
    return productoFiltrado.length > 0 ? productoFiltrado[0]["Cantidad"] : 0;
  };

  const ObtenerTallas = () => {
    let tallas = [];
    for (let talla of producto.Variantes[0].Tallas) {
      if (talla.Cantidad > 0) {
        tallas.push({
          value: talla._id,
          label: talla.Talla,
          image:
            recursosUrlState + "/img/Filtros/" + talla.Talla + "-blanco.webp",
          Cantidad: talla.Cantidad,
          estado: false,
        });
      }
    }
    setTallas(tallas);
    //modificarTallasState(tallas);
  };

  //Recibe el evento de seleccion o deseleccion de las tallas y modifica su state
  const changeSelectedTalla = (value, talla) => {
    let temp = tallas;
    setAdicion(1);
    temp = temp.map((element) => {
      return { ...element, estado: value === element.value ? true : false };
    });
    // setCantidad(talla.Cantidad);
    obtenerCantidad(talla);
    setTallaSelecionada(talla);
    setTallas(temp);
  };

  /** Establece la cantidad de unidades disponibles de una talla especifica.
   *  Si el producto tiene un componente, se establece la cantidad de ese
   *  componente, de lo contrario se establece la cantidad normalmente. */
  const obtenerCantidad = (talla) => {
    const hasProcomponente =  procomponente.has(producto.Referencia + talla.label);
    const codComponente = procomponente.get(producto.Referencia + talla.label)
    let undDisponible = 0;
    if (hasProcomponente) {
      undDisponible = componentes.get(codComponente);
    } else {
      undDisponible = talla.Cantidad;
    }
    setCantidad(undDisponible);
    return undDisponible;
  }

  /** Establece la condición para mostrar aviso de cantidades maximas en carrito */
  const aplicarCondicion = () => {
    const hasProcomponente = procomponente.has(producto.Referencia + tallaSeleccionada.label);
    let cantidadMaxima = !hasProcomponente
      ? cantidad === obtenerCantidadTallaProductoActual(tallaSeleccionada.label)
      : componentes.get(producto.Referencia + tallaSeleccionada.label) > 0;
    return cantidadMaxima;
  }  

  useEffect(() => {
    ObtenerTallas();
  }, []);

  return (
    <div id="tallas">
      {/* <p className="FuenteHelvetica">Tallas disponibles</p> */}

      {tallas?.map((talla, index) => (
        <img
          className="imgTalla"
          key={talla.label + index}
          alt={talla.label}
          src={
            talla.estado
              ? recursosUrlState + "/img/Filtros/" + talla.label + "-negro.webp"
              : recursosUrlState +
              "/img/Filtros/" +
              talla.label +
              "-blanco.webp"
          }
          id={talla.label}
          onClick={() => changeSelectedTalla(talla.value, talla)}
        />
      ))}

      <div>
        {cantidad === 0 ? (
          <hr
            style={{
              height: 25,
              marginLeft: "7%",
              marginRight: "7%"
            }}
          />
        ) : cantidad === 1 ? (
          <p className="" style={{ fontFamily: "Poppins-Light", fontSize: "1rem", textAlign: "center", color: "#FF0000" }}>
            ! Última unidad disponible¡
          </p>
        ) : (
          <p className="" style={{ fontFamily: "Poppins-Light", fontSize: "1rem", textAlign: "center" }}>
            {cantidad + " "} unidades disponibles
          </p>
        )}
        {tallaSeleccionada.length !== 0 ? (
          aplicarCondicion() ? (
            <p className="" style={{ fontFamily: "Poppins-Light", fontSize: "1.1rem", textAlign: "center", color: "#f00" }}>
              Cantidad Maxima en tu carrito
            </p>
          ) : (
            <>
              {vendedor ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <input
                    style={{ width: "50px", border: "solid 1px black", textAlign: "center" }}
                    type="number" name="" id=""
                    value={adicion}
                    onChange={(e) => handleChange(e)}
                    min={1}
                  />
                  <p
                    className="btn btnCantidad"
                    style={{
                      fontFamily: "Poppins-Light",
                      background: "black",
                      color: "white"
                    }}
                    onClick={() =>
                      adicionarV2(
                        tallaSeleccionada.label,
                        obtenerCantidadTallaProductoActual(tallaSeleccionada.label),
                        adicion
                      )
                    }
                  >
                    AGREGAR AL CARRITO
                  </p>
                </div>
              ) : (
                <img
                  src="https://hosting.dkda.com.co/AppWeb/img/Index/web/home/agregar%20al%20carrito.png"
                  id="agregar"
                  onClick={() =>
                    adicionar(
                      tallaSeleccionada.label,
                      obtenerCantidadTallaProductoActual(tallaSeleccionada.label)
                    )
                  }
                  style={{
                    height: "auto",
                    width: "100%",
                    marginTop: "-9px",
                  }}
                  alt="agregar"
                />
              )}
              <p className="" style={{ fontFamily: "Poppins-Light", fontSize: "1.1rem", textAlign: "center", color: "#49be25" }}>
                {obtenerCantidadTallaProductoActual(tallaSeleccionada.label) +
                  " "}{" "}
                Unidades en tu carrito
              </p>
            </>
          )
        ) : mostar ? (
          <>
            {vendedor ? (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <input
                  style={{ width: "50px", border: "solid 1px black", textAlign: "center" }}
                  type="number" name="" id=""
                  value={adicion}
                  onChange={(e) => handleChange(e)}
                  min={1}
                />
                <p
                  className="btn btnCantidad"
                  style={{
                    fontFamily: "Poppins-Light",
                    background: "black",
                    color: "white",
                  }}
                  onClick={() =>
                    adicionarV(
                      tallaSeleccionada.label,
                      obtenerCantidadTallaProductoActual(tallaSeleccionada.label),
                      adicion
                    )
                  }
                >
                  AGREGAR AL CARRITO
                </p>
              </div>
            ) : (
              <img
                src="https://hosting.dkda.com.co/AppWeb/img/Index/web/home/agregar%20al%20carrito.png"
                id="agregar"
                onClick={() =>
                  adicionar(
                    tallaSeleccionada.label,
                    obtenerCantidadTallaProductoActual(tallaSeleccionada.label)
                  )
                }
                style={{
                  height: "auto",
                  width: "80%",
                  marginTop: "-23px",
                }}
                alt="agregar"
              />
            )}
            <p className="" style={{ fontFamily: "Poppins-Light", fontSize: "1.1rem", textAlign: "center", color: "#49be25" }}>
              {obtenerCantidadTallaProductoActual(tallaSeleccionada.label) +
                " "}{" "}
              Unidades en tu carrito
            </p>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default GrupoTallas;
