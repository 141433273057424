import React from "react";
import ReactDOM from "react-dom";

//Importaciones de css
import "./components/Header/static/css/Header.css";
import "./components/Header/static/css/Header_Movil.css";
import "./components/Header/static/css/Header_Web.css";
import "./components/Index/static/css/Index.css";
import "./components/Index/static/css/Index_Movil.css";
import "./components/Index/static/css/Index_Web.css";
import "./components/Mercado/static/css/Filtros/Filtros.css";
import "./components/Mercado/static/css/GrupoTallas/Tallas.css";
import "./components/Mercado/static/css/Mayoristas/Mayoristas.css";
import "./components/Mercado/static/css/Mayoristas/Mayoristas_Web.css";
import "./components/Mercado/static/css/Mayoristas/Mayoristas_movil.css";
import "./components/Mercado/static/css/Mercado/Mercado.css";
import "./components/Mercado/static/css/Mercado/Mercado_MovilGrande.css";
import "./components/Mercado/static/css/Mercado/Mercado_MovilHorizontal.css";
import "./components/Mercado/static/css/Mercado/Mercado_MovilPequeño.css";
import "./components/Mercado/static/css/Mercado/Mercado_Web.css";
import "./static/css/General.css";
import "./static/css/Modal.css";
import "./components/Ayuda/static/css/Ayuda.css";
import "./components/Ayuda/static/css/AyudaMovil.css";
import "./components/Ayuda/static/css/AyudaWeb.css";
import "./components/Pedido/static/css/Pedido.css";
import "./components/Pedido/static/css/PedidoMovilGrande.css";
import "./components/Pedido/static/css/PedidoMovilHorizontal.css";
import "./components/Pedido/static/css/PedidoMovilPequeño.css";
import "./components/Pedido/static/css/PedidoTablet.css";
import "./components/Pedido/static/css/PedidoWeb.css";
import "./components/Usuario/static/css/InicioSesion/InicioSesion.css";
import "./components/Usuario/static/css/InicioSesion/InicioSesionMovil.css";
import "./components/Usuario/static/css/InicioSesion/InicioSesionWeb.css";
import "./components/Usuario/static/css/Registro/Registro.css";
import "./components/Usuario/static/css/Registro/RegistroMovil.css";
import "./components/Usuario/static/css/Registro/RegistroWeb.css";

import App from "./App";

import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "hint.css/hint.min.css";
import "react-simple-hook-modal/dist/styles.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
