import React, { useEffect, useState } from "react";
import axios from "axios";
import "../static/Indicadores.css";
import { useHistory } from "react-router-dom";

const Unidades = ({ SetLandingHeader, apiUrlState, recursosUrlState }) => {
  const VolverAdmin = () => {
    window.location.href = "/Admin/Index/MenuUnidades";
  };
  const [ListaUnidades, GetListaUnidades] = useState([
    {
      grupo: "PRUEBA",
      S: 0,
      M: 0,
      L: 0,
      XL: 0,
      XXL: 0,
      U: 0,
      Total: 0,
    },
  ]);
  const [TotalUnidades, GetTotalUnidades] = useState({
    S: 0,
    M: 0,
    L: 0,
    XL: 0,
    XXL: 0,
    U: 0,
    Total: 0,
  });

  const S = 0;

  //Login validation
  const token = localStorage.getItem("token");
  const history = useHistory();
  useEffect(() => {
    if (token) {
      
    }else{
      history.push("/login")
    }
    //console.log(reports)
  },[token]);

  const obtenerUnidades = async (bodega) => {
    await axios
      .get(apiUrlState + "/Api/Unidades/ConteoUnidades/"+bodega)
      .then((response) => {
        GetListaUnidades(response.data.data);
        GetTotalUnidades(response.data.total);
      })
      .catch(function (err) {
        console.log("Error");
      });
  };

  useEffect(() => {
    SetLandingHeader({ Estado: true });
    obtenerUnidades(3);
  }, []);

  return (
    <div>
      {/* <div className=" row volver" onClick={() => VolverAdmin()}>
        <p className="FuenteBebas" style={{ cursor: "pointer" }}>
          Volver
        </p>
        <img
          className="botonIzquierda"
          src={
            recursosUrlState +
            "/img/Index/web/botones/flecha-izquierda-negro.webp"
          }
          alt="flechaIzquierda"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src =
              recursosUrlState +
              "/img/Index/web/botones/flecha-izquierda-negro.png";
          }}
        ></img>
      </div> */}
        <h1
          style={{ marginTop: "150px", textAlign: "center", fontWeight: "bold" }}
          >
          Unidades en Bodega
        </h1>
      <div style={{textAlign:"center"}}>
        <button className="btn btn-dark"
        onClick={()=> obtenerUnidades(3)}
        >
          Principal
        </button>
        <button className="btn btn-dark"
          onClick={()=> obtenerUnidades(6)}
        >
          Cali
        </button>
        <button className="btn btn-dark"
          onClick={()=> obtenerUnidades(13)}
        >
          Preventa
        </button>
        <button className="btn btn-dark"
          onClick={()=> obtenerUnidades(7)}
        >
          Príncipe
        </button>
        <button className="btn btn-dark"
          onClick={()=> obtenerUnidades(11)}
        >
          Dubai
        </button>

      </div>
      <div className="container">
        <div className="table-responsive">
          <table className="table">
            <thead style={{ marginTop: "50px", textAlign: "center" }}>
              <tr>
                <td
                  rowSpan="2"
                  colSpan="1"
                  scope="col"
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                >
                  Linea
                </td>

                <td
                  colSpan="6"
                  rowSpan="1"
                  scope="col"
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                >
                  Tallas
                </td>

                <td
                  rowSpan="2"
                  colSpan="1"
                  scope="col"
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  align="center"
                >
                  Total
                </td>
              </tr>
              <tr>
                <td
                  scope="col"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                  S
                </td>
                <td
                  scope="col"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                  M
                </td>
                <td
                  scope="col"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                  L
                </td>
                <td
                  scope="col"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                  XL
                </td>
                <td
                  scope="col"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                  XXL
                </td>
                <td
                  scope="col"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                  U
                </td>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {ListaUnidades.map((grupo) => (
                <tr style={{ textAlign: "center" }}>
                  <th scope="col">{grupo.grupo}</th>
                  <th>{grupo.S}</th>
                  <th>{grupo.M}</th>
                  <th>{grupo.L}</th>
                  <th>{grupo.XL}</th>
                  <th>{grupo.XXL}</th>
                  <th>{grupo.U}</th>
                  <th>{grupo.Total}</th>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr style={{ textAlign: "center" }}>
                <td
                  rowSpan="2"
                  colSpan="1"
                  scope="col"
                  style={{ fontWeight: "bold", fontSize: "30px" }}
                >
                  Total
                </td>
                <td>{TotalUnidades.S}</td>
                <td>{TotalUnidades.M}</td>
                <td>{TotalUnidades.L}</td>
                <td>{TotalUnidades.XL}</td>
                <td>{TotalUnidades.XXL}</td>
                <td>{TotalUnidades.U}</td>
                <td>{TotalUnidades.Total}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Unidades;
