import "../static/Indicadores.css";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

const MenuPrincipal = ({}) => {
  const token = localStorage.getItem("token");
  const history = useHistory();
  useEffect(() => {
    if (token) {
      
    }else{
      history.push("/login")
    }
    //console.log(reports)
  },[token]);
  return (
    <div>
      <div className="containedor container">
        <div className="row justify-content-center">
          <div className="col-auto text-center">
            <Link to="/Admin/Indicadores">
              <p
                className="botonLimpiarFiltros text-center FuenteBebas"
                style={{ cursor: "pointer", width: "300px" }}
              >
                VENTAS
              </p>
            </Link>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-auto text-center">
            <Link to="/Admin/Index/MenuUnidades">
              <p
                className="botonLimpiarFiltros text-center FuenteBebas"
                style={{ cursor: "pointer", width: "300px" }}
              >
                UNIDADES
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuPrincipal;
