import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../static/Indicadores.css";

const MenuUnidades = ({ SetLandingHeader, apiUrlState, recursosUrlState }) => {
  const VolverAdmin = () => {
    window.location.href = "/Admin/Index/MenuPrincipal";
  };
  const token = localStorage.getItem("token");
  const history = useHistory();
  useEffect(() => {
    if (token) {
      
    }else{
      history.push("/login")
    }
    //console.log(reports)
  },[token]);
  return (
    <div>
      <div className="center_element container">
        <div className="row justify-content-center">
          <div className="col-auto text-center">
            <Link to="/Admin/Rotacion">
              <p
                className="botonLimpiarFiltros text-center FuenteBebas"
                style={{ cursor: "pointer", width: "300px" }}
              >
                ROTACIÓN UNIDADES
              </p>
            </Link>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-auto text-center">
            <Link to="/Admin/Unidades">
              <p
                className="botonLimpiarFiltros text-center FuenteBebas"
                style={{ cursor: "pointer", width: "300px" }}
              >
                DISPONIBLE
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuUnidades;
