//Zona de importacion de componentes externos.
import React, { useEffect, useState } from "react";
import swal from "sweetalert";

//Zona de importacion de componentes internos.
import axios from "axios";
import { usePreventaContext } from "../../providers/PreventaProviders";

/** Importacion de servicios */
import apiFacebook from "../../services/apiFacebook";

//Zona de importacion de Css.
import "./static/css/GrupoTallas/Tallas.css";
import { useGlobalContext } from "../../providers/GlobalStatesProviders";

const GrupoTallasV2 = ({
  carrito,
  modificarCarritoState,
  producto,
  apiUrlState,
  recursosUrlState,
  headerState,
  actualizarSessionStorage,
  mostar,
}) => {
  const { vendedor } = usePreventaContext();
  const { procomponente, componentes, actualizarComponente } = useGlobalContext();

  const [tallas, setTallas] = useState([]);
  const [tallaSeleccionada, setTallaSelecionada] = useState([]);
  const [cantidad, setCantidad] = useState(0); // Unidades en inventario
  const [cantidadComp, setCantidadComp] = useState(0); // Unidades del componente
  const [unidadesDisponibles, setUnidadesDisponibles] = useState(0); // cantidad + cantidadComp
  const [actualDisponible, setActualDisponible] = useState(-1);
  const [adicion, setAdicion] = useState(0);

  /** Envia el evento de agregar al carrito pagina web a facebook */
  const enviarEventoFacebook = async (producto) => {
    const userIP = await apiFacebook.get();
    const item_price =  headerState["Cliente"] == "Mayoristas"
      ? producto.PrecioMayorista
      : producto.PrecioMinorista;
    apiFacebook.post(`${apiUrlState}/Api/Facebook/SendEvent`, {
      event_id: `cart-${Date.now()}`,
      event_name: "AddToCart",
      event_source_url: window.location.href,
      client_user_agent: navigator.userAgent,
      client_ip_address: userIP || "0.0.0.0",
      custom_data: {
        currency: "COP",
        contents: [
          {
            id: producto.id,
            item_price,
          }
        ]
      }
    });

    /** Activar evento mediante pixel -> Pendiente */
    
  }

  /** Control del input de cantidades para que no se puedan ingresar mas de dos digitos */
  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,2}$/.test(value)) { // Limitar a 2 dígitos
      setAdicion(value);
    }
  };

  //Retorna el precio ingresado sin signos de peso ni puntos.
  const arreglarPrecio = (precio) => {
    var separacion = precio;
    if (precio.indexOf("$") !== -1) separacion = precio.split(" ")[1];
    var separacion2 = separacion.split(".");
    return parseInt(separacion2[0] + separacion2[1]);
  };

  //Se realiza la validacion en bodega para aumentar en 1 la cantidad en carrito de este, si no existe lo crea.
  //Actualiza el state del carrito y tambien el sessionStorage.
  const adicionar = async (talla, cantidadActual) => {
    setActualDisponible(cantidad - cantidadActual);
    if (tallaSeleccionada.length !== 0) {      
      if (cantidadActual + 1 <= unidadesDisponibles) {
        let crear = true;
        for (var i = 0; i < carrito["Carrito"].length; i++) {
          if (producto.Referencia + talla === carrito["Carrito"][i]["id"]) {
            carrito["Carrito"][i]["Cantidad"] += 1;
            crear = false;
          }
        }
        if (crear) {
          const productoNuevo = {
            _id: producto._id,
            id: producto.Referencia + talla,
            Nombre: producto.Nombre,
            Codigo: producto.Referencia,
            Componente: procomponente.get(producto.Referencia + talla),
            Talla: talla,
            Bodega: producto.CodBodega,
            Categoria: producto.Categoria.Categoria,
            PrecioMinorista: arreglarPrecio(producto.PrecioMinorista),
            PrecioMayorista: arreglarPrecio(producto.PrecioMayorista),
            DescuentoMayorista: producto.DescuentoMayorista,
            DescuentoMinorista: producto.DescuentoMinorista,
            Cantidad: 1,
          };
          const carritoNew = [...carrito["Carrito"], productoNuevo];
          modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
          actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
          enviarEventoFacebook(productoNuevo)
        } else {
          const carritoNew = [...carrito["Carrito"]];
          modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
          actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
        }
        actualizarComponente(producto.Referencia + talla, - 1) // Aqui revisar
      } else {
        swal({
          title: "¡Importante!",
          text: `No puedes agregar más unidades de la referencia ${producto.Referencia + talla}\
          al carrito. Si se presenta la novedad con todas las referencias, solicite soporte.`,
          icon: "warning",
        });
      }
    }
  };

  /** Reparte las unidades que se piden del inventario
   *  y las que se piden de los componentes. */
  const separarUnidades = (undAdicion, cantidadActual) => {
    let undIventario = undAdicion;
    let undComponente = 0;
    if (cantidadActual >= cantidad) {
      undIventario = 0;
      undComponente = undAdicion;
    } else if (cantidadActual + undAdicion > cantidad) {
      undIventario = cantidad - cantidadActual;
      undComponente = undAdicion - undIventario;
    }
    console.log("Separar", undIventario, undComponente, undAdicion, cantidadActual >= cantidad, cantidadActual + undAdicion > cantidad);
    return { undIventario, undComponente };
  }

  /** Agrega productos al carrito, gestionando cantidades en inventario y componentes */
  const adicionarV2 = async (talla, cantidadActual, cantidadAdicion) => {
    const totalDisponibles = cantidadActual > cantidad? cantidadComp : cantidad + cantidadComp - cantidadActual;
    const { undIventario, undComponente } = separarUnidades(cantidadAdicion, cantidadActual);
    const disponible = (undIventario > 0 || undComponente > 0) && undComponente <= cantidadComp;
    // console.log("TotalDispon",totalDisponibles, "CantComp",cantidadComp, "Cantidad", cantidad, disponible);
    setActualDisponible(totalDisponibles);
    
    if (tallaSeleccionada.length !== 0) {
      if (disponible) {
        auxAdicionarV(talla, undIventario, undComponente);
        setAdicion(1);
      } else {
        if (cantidadAdicion > totalDisponibles) {
          const undPermitidas = totalDisponibles;
          if (undPermitidas > 0) {
            swal({
              title: "Aviso",
              text: `La cantidad solicitada de la referencia ${producto.Referencia + talla} no está disponible.
                Se agregarán ${undPermitidas} de ${cantidadAdicion} al carrito, desea continuar?`,
              icon: "info",
              buttons: {
                cancel: {
                  text: "No",
                  value: null,
                  visible: true,
                  closeModal: true,
                },
                confirm: {
                  text: "Si",
                  value: true,
                  visible: true,
                  closeModal: true
                }
              }
            }).then(response => {
              if (response) {
                const res = separarUnidades(undPermitidas, cantidadActual);
                auxAdicionarV(talla, res.undIventario, res.undComponente);
              }
              setAdicion(1);
            })
          } else {
            swal({
              title: "¡Importante!",
              text: `No puedes agregar más unidades de la referencia ${producto.Referencia + talla}\
              al carrito. Si se presenta la novedad con todas las referencias, solicite soporte.`,
              icon: "warning",
            });
          }
        }
      }
    }
  };

  const auxAdicionarV = (talla, undIventario, undComponente) => {
    const totalAdicion = parseInt(undIventario) + parseInt(undComponente);
    let crear = true;
    for (var i = 0; i < carrito["Carrito"].length; i++) {
      if (producto.Referencia + talla === carrito["Carrito"][i]["id"]) {
        carrito["Carrito"][i]["Cantidad"] += parseInt(totalAdicion);
        carrito["Carrito"][i]["CantidadComp"] += parseInt(undComponente);
        setCantidadComp(cantidadComp - undComponente);
        crear = false;
      }
    }
    if (crear) {
      const productoNuevo = {
        _id: producto._id,
        id: producto.Referencia + talla,
        Nombre: producto.Nombre,
        Codigo: producto.Referencia,
        Componente: procomponente.get(producto.Referencia + talla),
        CantidadComp: undComponente,
        Talla: talla,
        Bodega: producto.CodBodega,
        Categoria: producto.Categoria.Categoria,
        PrecioMinorista: arreglarPrecio(producto.PrecioMinorista),
        PrecioMayorista: arreglarPrecio(producto.PrecioMayorista),
        DescuentoMayorista: producto.DescuentoMayorista,
        DescuentoMinorista: producto.DescuentoMinorista,
        Cantidad: totalAdicion,
      };
      setCantidadComp(cantidadComp - undComponente);
      const carritoNew = [...carrito["Carrito"], productoNuevo];
      modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
      actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
    } else {
      const carritoNew = [...carrito["Carrito"]];
      modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
      actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
    }
    actualizarComponente(producto.Referencia + talla, - undComponente) // Aqui revisar

  }

  /** Obtiene las unidades pedidas de una referencia en el carrito */
  const obtenerCantidadTallaProductoActual = (talla) => {
    const productoFiltrado = carrito["Carrito"].filter(
      (prod) => prod["id"] === producto.Referencia + talla
    );
    return productoFiltrado.length > 0 ? productoFiltrado[0]["Cantidad"] : 0;
  };

  const ObtenerTallas = () => {
    let tallas = [];
    for (let talla of producto.Variantes[0].Tallas) {
      const condicion = vendedor
        ? talla.Cantidad > 0 || talla.Componente > 0
        : talla.Cantidad > 0;
      if (condicion) {
        tallas.push({
          value: talla._id,
          label: talla.Talla,
          image:
            recursosUrlState + "/img/Filtros/" + talla.Talla + "-blanco.webp",
          Cantidad: talla.Cantidad,
          estado: false,
        });
      }
    }
    setTallas(tallas);
    //modificarTallasState(tallas);
  };

  //Recibe el evento de seleccion o deseleccion de las tallas y modifica su state
  const changeSelectedTalla = (value, talla) => {
    let temp = tallas;
    setAdicion(1);
    temp = temp.map((element) => {
      return { ...element, estado: value === element.value ? true : false };
    });
    setCantidad(talla.Cantidad);
    obtenerCantidadComp(talla);
    setTallaSelecionada(talla);
    setTallas(temp);
  };

  /** Establece las unidades disponibles del componente */
  const obtenerCantidadComp = (talla) => {
    const hasProcomponente =  procomponente.has(producto.Referencia + talla.label);
    const codComponente = procomponente.get(producto.Referencia + talla.label);
    let undDisponible = 0;
    if (hasProcomponente) {
      undDisponible = componentes.get(codComponente);
    } 
    setCantidadComp(undDisponible);
    if (vendedor) {
      setUnidadesDisponibles(parseInt(talla.Cantidad) + parseInt(undDisponible));
    } else {
      setUnidadesDisponibles(talla.Cantidad);
    }

  }

  useEffect(() => {
    ObtenerTallas();
  }, []);

  useEffect(()=> {
    setActualDisponible(-1)
  }, [tallas]);

  return (
    <div id="tallas">
      {tallas?.map((talla, index) => (
        <img
          className="imgTalla"
          key={talla.label + index}
          alt={talla.label}
          src={
            talla.estado
              ? recursosUrlState + "/img/Filtros/" + talla.label + "-negro.webp"
              : recursosUrlState +
              "/img/Filtros/" +
              talla.label +
              "-blanco.webp"
          }
          id={talla.label}
          onClick={() => changeSelectedTalla(talla.value, talla)}
        />
      ))}

      <div>
        {unidadesDisponibles === 0 ? (
          <hr
            style={{
              height: 25,
              marginLeft: "7%",
              marginRight: "7%"
            }}
          />
        ) : unidadesDisponibles === 1 ? (
          <p className="" style={{ fontFamily: "Poppins-Light", fontSize: "1rem", textAlign: "center", color: "#FF0000" }}>
            ! Última unidad disponible¡
          </p>
        ) : (
          <p className="" style={{ fontFamily: "Poppins-Light", fontSize: "1rem", textAlign: "center" }}>
            {unidadesDisponibles + " "} unidades disponibles
          </p>
        )}
        {tallaSeleccionada.length !== 0 ? (
          actualDisponible == 0 ? (
            <p className="" style={{ fontFamily: "Poppins-Light", fontSize: "1.1rem", textAlign: "center", color: "#f00" }}>
              Cantidad Maxima en tu carrito
            </p>
          ) : (
            <>
              {vendedor ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <input
                    style={{ width: "50px", border: "solid 1px black", textAlign: "center" }}
                    type="number" name="" id=""
                    value={adicion}
                    onChange={(e) => handleChange(e)}
                    min={1}
                  />
                  <p
                    className="btn btnCantidad"
                    style={{
                      fontFamily: "Poppins-Light",
                      background: "black",
                      color: "white"
                    }}
                    onClick={() =>
                      adicionarV2(
                        tallaSeleccionada.label,
                        obtenerCantidadTallaProductoActual(tallaSeleccionada.label),
                        parseInt(adicion)
                      )
                    }
                  >
                    AGREGAR AL CARRITO
                  </p>
                </div>
              ) : (
                <img
                  src="https://hosting.dkda.com.co/AppWeb/img/Index/web/home/agregar%20al%20carrito.png"
                  id="agregar"
                  onClick={() =>
                    adicionar(
                      tallaSeleccionada.label,
                      obtenerCantidadTallaProductoActual(tallaSeleccionada.label)
                    )
                  }
                  style={{
                    height: "auto",
                    width: "100%",
                    marginTop: "-9px",
                  }}
                  alt="agregar"
                />
              )}
              <p className="" style={{ fontFamily: "Poppins-Light", fontSize: "1.1rem", textAlign: "center", color: "#49be25" }}>
                {obtenerCantidadTallaProductoActual(tallaSeleccionada.label) +
                  " "}{" "}
                Unidades en tu carrito
              </p>
            </>
          )
        ) : mostar ? (
          <>
            {vendedor ? (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <input
                  style={{ width: "50px", border: "solid 1px black", textAlign: "center" }}
                  type="number" name="" id=""
                  value={adicion}
                  onChange={(e) => handleChange(e)}
                  min={1}
                />
                <p
                  className="btn btnCantidad"
                  style={{
                    fontFamily: "Poppins-Light",
                    background: "black",
                    color: "white",
                  }}
                  onClick={() =>
                    adicionarV2(
                      tallaSeleccionada.label,
                      obtenerCantidadTallaProductoActual(tallaSeleccionada.label),
                      adicion
                    )
                  }
                >
                  AGREGAR AL CARRITO
                </p>
              </div>
            ) : (
              <img
                src="https://hosting.dkda.com.co/AppWeb/img/Index/web/home/agregar%20al%20carrito.png"
                id="agregar"
                onClick={() =>
                  adicionar(
                    tallaSeleccionada.label,
                    obtenerCantidadTallaProductoActual(tallaSeleccionada.label)
                  )
                }
                style={{
                  height: "auto",
                  width: "80%",
                  marginTop: "-23px",
                }}
                alt="agregar"
              />
            )}
            <p className="" style={{ fontFamily: "Poppins-Light", fontSize: "1.1rem", textAlign: "center", color: "#49be25" }}>
              {obtenerCantidadTallaProductoActual(tallaSeleccionada.label) +
                " "}{" "}
              Unidades en tu carrito
            </p>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default GrupoTallasV2;
