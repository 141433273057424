import React from "react";
import "./style.css";
const ModalAddi = ({ accion, Mensaje }) => {
  return (
    <>
      <div
        className="modal fade"
        id="exampleModalAddi"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalAddiLabel"
        aria-hidden="true"
      >
        {Mensaje < 50000 ? (
          <div className="modal-dialog" role="document" style={{ zIndex: "99999" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalAddiLabel">
                  No es posible finalizar tu compra con ADDI
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <p className="FuenteBebas titulosPaso2">
                    Tu compra debe ser superior a $50.000
                  </p>
                </div>               
              </div>

              <div className="modal-footer" style={{justifyContent: "center"}}>
                <p
                  className="botonPaso2 text-center FuenteBebas"
                  style={{ cursor: "pointer" }}
                  data-dismiss="modal"
                >
                  Cerrar
                </p>
              </div>

            </div>
          </div>
        ) : (
          <div className="modal-dialog" role="document" style={{ zIndex: "99999" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalAddiLabel">
                  Finaliza tu compra con ADDI
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <p className="FuenteBebas titulosPaso2">
                    Es simple, rápido y seguro:
                  </p>
                  <p>
                    &#9989; Sin tarjeta de crédito y en minutos.
                  </p>
                  <p>
                    &#9989; Paga tu primera cuota un mes despues de la compra.
                  </p>
                  <p>
                    &#9989; Proceso 100% online. Sin papeleo ni costos ocultos.
                  </p>
                </div>
                
                <div className="tab-content" id="nav-tabContent">
                  
                </div>
                  
                  
                  
              </div>
              <div className="modal-footer" style={{justifyContent: "center"}}>
                {/* <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button> */}
                {/* <p
                  className="botonPaso2 text-center FuenteBebas"
                  style={{ cursor: "pointer" }}
                  data-dismiss="modal"
                >
                  Cerrar
                </p>
                <p
                  className="botonPaso2 text-center FuenteBebas"
                  style={{ cursor: "pointer" }}
                  data-dismiss="modal"
                  onClick={accion}
                >
                  Ir a pagar
                </p> */}
                <div style={{display: "flex", flexDirection: "column"}}>
                  <button className="btn btn-warning" style={{borderRadius: "30px", color: "black", fontWeight: "bold"}} 
                  onClick={accion}
                  data-dismiss="modal"
                  >Paga a cuotas con ADDI</button>
                </div>
              </div>
            </div>
          </div>
          
        )}
      </div>
    </>
  );
};

export default ModalAddi;