import React,  { useEffect, useState } from 'react'

const Descuentos = ({varsDesc}) => {


  const token = localStorage.getItem("token");
  const { InicioDesc, FinDesc, Descuento } = varsDesc;
  return (
    <>
        <p style={{ backgroundColor: "#f7f4ef", borderRadius: "9px" }}>
              <br/>
              🚨 <b>REBAJAS</b> 🚨<br/>

            Comenzó nuestra temporada de DESCUENTOS! <br/>
            <br/>
            Aprovecha desde <b>{InicioDesc}</b> hasta <b>{FinDesc}</b> hasta el <b>{Descuento}</b>  en prendas seleccionadas.
            <br/>
            INGRESA YA!
            <br/>
            <br/>
          </p>
    </>
  )
}

export default Descuentos;