//Zona de importacion de componentes externos.

import React from "react";
import "./style.css";
const ModalEpayco = ({ accion, Mensaje }) => {
  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document" style={{ zIndex: "99999" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Pago con epayco
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <p className="FuenteBebas titulosPaso2">
                  Te ofrecemos una amplia variedad de medios de pago
                </p>
                <p>
                  Aceptamos más de 22 medios de pago diferentes, tu decides cual
                  es el más conveniente para tus clientes. ¡Estamos
                  comprometidos a hacer que el proceso de compra sea lo más
                  sencillo y seguro posible para nuestros clientes!, si deseas continuar da click en el boton <strong>"IR A PAGAR"</strong>.
                </p>
              </div>
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <a
                    className="nav-item nav-link active"
                    id="Tarjeta-de-credito-btn"
                    data-toggle="tab"
                    href="#Tarjeta-de-credito"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                    style={{color:"black"}}
                  >
                    Tarjeta de credito
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="nav-profile-tab"
                    data-toggle="tab"
                    href="#nav-profile"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                    style={{color:"black"}}
                  >
                    Billetera digital
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="nav-contact-tab"
                    data-toggle="tab"
                    href="#nav-contact"
                    role="tab"
                    aria-controls="nav-contact"
                    aria-selected="false"
                    style={{color:"black"}}
                  >
                    Efectivo
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="pay-Bancarios"
                    data-toggle="tab"
                    href="#pay-Bancario"
                    role="tab"
                    aria-controls="nav-Bancario"
                    aria-selected="false"
                    style={{color:"black"}}
                  >
                    Transferencia Bancaria
                  </a>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="Tarjeta-de-credito"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="grid-container">
                    <img
                      className="imgPay"
                      src="https://epayco.com/wp-content/uploads/2023/04/Group-626347.png"
                    />
                    <img
                      className="imgPay"
                      src="https://epayco.com/wp-content/uploads/2023/04/Group-626350.png"
                    />
                    <img
                      className="imgPay"
                      src="https://epayco.com/wp-content/uploads/2023/04/Group-626353.png"
                    />
                    <img
                      className="imgPay"
                      src="https://epayco.com/wp-content/uploads/2023/04/Group-626348.png"
                    />
                    <img
                      className="imgPay"
                      src="https://epayco.com/wp-content/uploads/2023/04/Vector-3.png"
                    />
                    <img
                      className="imgPay"
                      src="https://epayco.com/wp-content/uploads/2023/04/Group-626354.png"
                    />
                    <img
                      className="imgPay"
                      src="https://epayco.com/wp-content/uploads/2023/04/Group-626355.png"
                    />
                    <img
                      className="imgPay"
                      src="https://epayco.com/wp-content/uploads/2023/04/Group-626349.png"
                    />
                    <img
                      className="imgPay"
                      src="https://epayco.com/wp-content/uploads/2023/04/Group-626352.png"
                    />
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <div className="grid-container">
                    <p></p>
                    <img
                      className="imgPay"
                      src="https://epayco.com/wp-content/uploads/2023/04/Group-626359.png"
                    />
                    <p></p>
                    <p></p>
                    <img
                      className="imgPay"
                      src="https://epayco.com/wp-content/uploads/2023/04/Group-626360.png"
                    />
                    <p></p>
                    <p></p>
                    <img
                      className="imgPay"
                      src="https://epayco.com/wp-content/uploads/2023/04/Group-626361.png"
                    />
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-contact"
                  role="tabpanel"
                  aria-labelledby="nav-contact-tab"
                >
                  <div className="contentPay">
                    <img
                      className="imgPayEfectivo"
                      src="https://sp-ao.shortpixel.ai/client/to_auto,q_glossy,ret_img,w_1024,h_565/https://epayco.com/wp-content/uploads/2023/04/Frame-626295-2-1024x565.png"
                    />
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pay-Bancario"
                  role="tabpanel"
                  aria-labelledby="pay-Bancario"
                >
                  <div className="grid-container">
                    <p></p>
                    <img
                      className="imgPay"
                      src="https://epayco.com/wp-content/uploads/2023/04/Group-626365.png"
                    />
                    <p></p>
                    <p></p>
                    <img
                      className="imgPay"
                      src="https://epayco.com/wp-content/uploads/2023/04/Group-626363.png"
                    />
                    <p></p>
                    <p></p>
                    <img
                      className="imgPay"
                      src="https://epayco.com/wp-content/uploads/2023/04/Group-626364.png"
                    />
                  </div>
                </div>
                </div>
            </div>
            <div className="modal-footer">
              {/* <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button> */}
              <p
                className="botonPaso2 text-center FuenteBebas"
                style={{ cursor: "pointer" }}
                data-dismiss="modal"
              >
                Cerrar
              </p>
              <p
                className="botonPaso2 text-center FuenteBebas"
                style={{ cursor: "pointer" }}
                data-dismiss="modal"
                onClick={accion}
              >
                Ir a pagar
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalEpayco;
